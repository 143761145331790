import { CanvasView } from './components/canvas';

import { initialEdges, initialNodes } from './templates';

function App() {
  return (
    <>
      <CanvasView {...{ initialEdges, initialNodes }} />
    </>
  );
}

export default App;
