import React from 'react';
import {
  CanvasElementsBuilder,
  CanvasPagesBuilder,
} from '../../../customNode/canvasElements';
import styles from './DialogContent.module.css';
import {
  nodeTypesListAction,
  nodeTypesListTools,
  nodeTypesPages,
} from '../../../customNode/nodeTypes';
import ButtonTemplate from './ButtonTemplate';

export default function DialogContent({ type, onDragStart }) {
  const content = () => {
    switch (type) {
      case 'templates':
        return <ButtonTemplate id={1} />;

      case 'page':
        return nodeTypesPages.map((tool) => (
          <CanvasPagesBuilder
            key={tool}
            type={tool}
            onDragStart={(event) => onDragStart(event, 'canvasPage', tool)}
          />
        ));

      case 'tools':
        return nodeTypesListTools.map((tool) => (
          <CanvasElementsBuilder
            key={tool}
            type={tool}
            onDragStart={(event) => onDragStart(event, 'canvasElement', tool)}
          />
        ));

      case 'actions':
        return (
          <>
            {nodeTypesListAction.map((tool) => (
              <CanvasElementsBuilder
                key={tool}
                type={tool}
                onDragStart={(event) =>
                  onDragStart(event, 'canvasElement', tool)
                }
              />
            ))}
            <CanvasElementsBuilder
              key={'condition'}
              type={'condition'}
              onDragStart={(event) =>
                onDragStart(event, 'canvasElementCondition', 'condition')
              }
            />
          </>
        );

      default:
        return <>DialogContent</>;
    }
  };

  return <div className={styles.content}>{content()}</div>;
}
