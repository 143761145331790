import React, { useEffect, useRef, useState } from 'react';
import styles from './ContextMenu.module.css';
import IconButton from '../iconButton';

import ReactDOM from 'react-dom';

function Portal({ children }) {
  return ReactDOM.createPortal(
    children,
    document.getElementById('root-portal'),
  );
}

export default function ContextMenu({
  children,
  position,
  isOpen,
  onClose,
  buttonClose = true,
}) {
  const contextMenuRef = useRef(null);
  useEffect(() => {
    const close = (e) => {
      if (contextMenuRef?.current?.contains(e.target)) {
        return;
      }
      isOpen && typeof onClose === 'function' && onClose();
    };
    if (!isOpen) {
      document.removeEventListener('click', close);
      return;
    }
    document.addEventListener('click', close);
    return () => {
      document.removeEventListener('click', close);
    };
  }, [isOpen, onClose]);

  const [stylePosition, setStylePosition] = useState({
    top: position?.top,
    left: position?.left,
  });
  useEffect(() => {
    if (!contextMenuRef?.current) {
      return;
    }
    const padding = 10;
    const clientWidth = (contextMenuRef?.current?.clientWidth ?? 100) + padding;
    const clientHeight =
      (contextMenuRef?.current?.clientHeight ?? 50) + padding * 3;

    setStylePosition({
      top: `min(${position?.top}px, 100vh - ${clientHeight}px)`,
      left: `min(${position?.left}px, 100vw - ${clientWidth}px)`,
    });
    return () => {};
  }, [
    setStylePosition,
    contextMenuRef?.current?.clientWidth,
    contextMenuRef?.current?.clientHeight,
    position?.top,
    position?.left,
  ]);
  return (
    isOpen && (
      <Portal>
        <div
          ref={contextMenuRef}
          className={`${styles.root} ${
            !buttonClose && styles.buttonClosePadding
          }`}
          style={stylePosition}
        >
          {children}
          {buttonClose && (
            <div className={styles.buttonClose}>
              <IconButton icon={'close'} onClick={onClose} />
            </div>
          )}
        </div>
      </Portal>
    )
  );
}
