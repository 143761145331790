import React from 'react';

// import clsx from 'clsx';

import IconMaterial from '../icon/IconMaterial.js';

import styles from './Divider.module.css';

export default function Divider({
  style,
  // className,
  children,
  content,
  icon,
  iconPosition = 'left',
}) {
  // const updatedClass = clsx(defaultClasses, className);

  return (
    <div className={styles.root} style={style}>
      <span className={styles.content}>
        {iconPosition === 'left' && icon && (
          <IconMaterial name={icon} style={{ marginRight: '4px' }} />
        )}
        {content || children}
        {iconPosition === 'right' && icon && (
          <IconMaterial name={icon} style={{ marginLeft: '4px' }} />
        )}
      </span>
    </div>
  );
}
