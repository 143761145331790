export const twitter = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="24"
    viewBox="0 0 26 24"
    fill="none"
  >
    <g clipPath="url(#clip0_4772_44080)">
      <path
        d="M20.4659 0H24.4337L15.7671 9.97189L26 23.494H17.9598L11.6948 15.2972L4.48996 23.494H0.522088L9.81526 12.8434L0 0H8.249L13.9398 7.51807L20.4659 0ZM19.0562 21.0924H21.249L7.04819 2.24498H4.64659L19.0562 21.0924Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_4772_44080">
        <rect width="26" height="23.494" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
