export const socialPlan = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M21.0004 12.7999C21.4422 12.7999 21.8004 12.4417 21.8004 11.9999C21.8004 11.5581 21.4422 11.1999 21.0004 11.1999V12.7999ZM20.8004 11.9999C20.8004 16.86 16.8605 20.7999 12.0004 20.7999V22.3999C17.7442 22.3999 22.4004 17.7437 22.4004 11.9999H20.8004ZM12.0004 20.7999C7.14028 20.7999 3.20039 16.86 3.20039 11.9999H1.60039C1.60039 17.7437 6.25663 22.3999 12.0004 22.3999V20.7999ZM3.20039 11.9999C3.20039 7.1398 7.14028 3.1999 12.0004 3.1999V1.5999C6.25663 1.5999 1.60039 6.25614 1.60039 11.9999H3.20039ZM12.0004 3.1999C16.8605 3.1999 20.8004 7.1398 20.8004 11.9999H22.4004C22.4004 6.25614 17.7442 1.5999 12.0004 1.5999V3.1999ZM12.0004 20.7999C11.6852 20.7999 11.3218 20.6572 10.9216 20.2703C10.5175 19.8797 10.1171 19.2758 9.76347 18.4675C9.05724 16.8532 8.60039 14.5672 8.60039 11.9999H7.00039C7.00039 14.7345 7.48375 17.2485 8.29762 19.1088C8.70403 20.0377 9.20862 20.8397 9.80951 21.4206C10.4142 22.0053 11.1558 22.3999 12.0004 22.3999V20.7999ZM8.60039 11.9999C8.60039 9.43259 9.05724 7.14657 9.76347 5.53233C10.1171 4.72402 10.5175 4.12012 10.9216 3.72948C11.3218 3.34255 11.6852 3.1999 12.0004 3.1999V1.5999C11.1558 1.5999 10.4142 1.99451 9.80951 2.57916C9.20862 3.16008 8.70403 3.96207 8.29762 4.89102C7.48375 6.75129 7.00039 9.26528 7.00039 11.9999H8.60039ZM12.0004 22.3999C12.845 22.3999 13.5865 22.0053 14.1913 21.4206C14.7922 20.8397 15.2967 20.0377 15.7032 19.1088C16.517 17.2485 17.0004 14.7345 17.0004 11.9999H15.4004C15.4004 14.5672 14.9435 16.8532 14.2373 18.4675C13.8837 19.2758 13.4832 19.8797 13.0792 20.2703C12.6789 20.6572 12.3156 20.7999 12.0004 20.7999V22.3999ZM17.0004 11.9999C17.0004 9.26528 16.517 6.75129 15.7032 4.89102C15.2967 3.96207 14.7922 3.16008 14.1913 2.57916C13.5865 1.99451 12.845 1.5999 12.0004 1.5999V3.1999C12.3156 3.1999 12.6789 3.34255 13.0792 3.72948C13.4832 4.12012 13.8837 4.72402 14.2373 5.53233C14.9435 7.14657 15.4004 9.43259 15.4004 11.9999H17.0004ZM2.40039 12.7999L21.0004 12.7999V11.1999L2.40039 11.1999L2.40039 12.7999Z"
      fill="#FCFCFC"
    />
  </svg>
);
