import React from 'react';
import styles from './Dialog.module.css';
import Button from '../button';

export default function Dialog({ dialogRef, close, children }) {
  const onClick = () => {
    if (typeof close === 'function') {
      close();
    }
  };
  return (
    <dialog ref={dialogRef} className={styles.root}>
      {children}
      <Button icon={'close'} onClick={onClick} className={styles.buttonClose} />
    </dialog>
  );
}
