import React from 'react';
import { Button } from '../../../../ui';
import styles from './ControlButtonWrapper.module.css';
import { IconCustom } from '../../../../ui/icon';

export default function ButtonStickyNotes({ onDragStart }) {
  return (
    <Button
      title={'Sticky Notes'}
      className={styles.root}
      style={{ cursor: 'grab' }}
      onDragStart={(event) => onDragStart(event, 'stickyNotes')}
      draggable
    >
      <IconCustom name={'stickyNotes'} />
    </Button>
  );
}
