export const optIn = (
  <svg
    width="161"
    height="182"
    viewBox="0 0 161 182"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_1629_22043)">
      <g clipPath="url(#clip0_1629_22043)">
        <rect
          x="19.5"
          y="15.8193"
          width="122"
          height="143"
          rx="4"
          fill="white"
        />
        <rect x="19.5" y="15.8193" width="122" height="13" fill="#F3EEFC" />
        <circle cx="26" cy="22.3193" r="2.5" fill="#939AA9" />
        <circle cx="34" cy="22.3193" r="2.5" fill="#939AA9" />
        <circle cx="42" cy="22.3193" r="2.5" fill="#939AA9" />
        <rect
          x="49.5"
          y="19.8193"
          width="86"
          height="5"
          rx="2.5"
          fill="white"
        />
        <rect
          x="31.5"
          y="84.8193"
          width="98"
          height="39"
          rx="2"
          fill="#5E21C0"
        />
        <rect
          x="102.5"
          y="106.819"
          width="20"
          height="6"
          rx="3"
          fill="#8750E1"
        />
        <rect x="37.5" y="106.819" width="63" height="6" fill="#F3EEFC" />
        <path
          d="M59.94 100.971C59.412 100.971 58.9667 100.889 58.604 100.723C58.2413 100.553 57.9667 100.323 57.78 100.035C57.5933 99.7419 57.5 99.4059 57.5 99.0272H58.772C58.772 99.1766 58.812 99.3152 58.892 99.4432C58.9773 99.5712 59.1053 99.6752 59.276 99.7552C59.4467 99.8299 59.668 99.8672 59.94 99.8672C60.1853 99.8672 60.3907 99.8352 60.556 99.7712C60.7213 99.7072 60.8467 99.6192 60.932 99.5072C61.0173 99.3899 61.06 99.2566 61.06 99.1072C61.06 98.9206 60.98 98.7739 60.82 98.6672C60.66 98.5552 60.4013 98.4832 60.044 98.4512L59.604 98.4112C59.0227 98.3632 58.5587 98.1819 58.212 97.8672C57.8653 97.5526 57.692 97.1366 57.692 96.6192C57.692 96.2459 57.78 95.9232 57.956 95.6512C58.1373 95.3792 58.388 95.1712 58.708 95.0272C59.028 94.8779 59.4067 94.8032 59.844 94.8032C60.3027 94.8032 60.6947 94.8832 61.02 95.0432C61.3507 95.1979 61.604 95.4192 61.78 95.7072C61.956 95.9899 62.044 96.3232 62.044 96.7072H60.764C60.764 96.5632 60.7293 96.4326 60.66 96.3152C60.5907 96.1926 60.4867 96.0939 60.348 96.0192C60.2147 95.9446 60.0467 95.9072 59.844 95.9072C59.652 95.9072 59.4893 95.9392 59.356 96.0032C59.228 96.0672 59.132 96.1552 59.068 96.2672C59.004 96.3739 58.972 96.4912 58.972 96.6192C58.972 96.7846 59.0307 96.9286 59.148 97.0512C59.2653 97.1739 59.4573 97.2459 59.724 97.2672L60.172 97.3072C60.5987 97.3446 60.9747 97.4379 61.3 97.5872C61.6253 97.7312 61.8787 97.9312 62.06 98.1872C62.2467 98.4379 62.34 98.7446 62.34 99.1072C62.34 99.4806 62.2413 99.8086 62.044 100.091C61.852 100.369 61.5773 100.585 61.22 100.739C60.8627 100.894 60.436 100.971 59.94 100.971Z"
          fill="#EEF3FD"
        />
        <path
          d="M65.6919 100.971C65.1426 100.971 64.6759 100.87 64.2919 100.667C63.9133 100.459 63.6253 100.166 63.4279 99.7872C63.2306 99.4086 63.1319 98.9606 63.1319 98.4432V94.9792H64.4439V98.4912C64.4439 98.7472 64.4919 98.9712 64.5879 99.1632C64.6893 99.3499 64.8306 99.4939 65.0119 99.5952C65.1986 99.6966 65.4253 99.7472 65.6919 99.7472C65.9639 99.7472 66.1906 99.6966 66.3719 99.5952C66.5586 99.4939 66.6999 99.3499 66.7959 99.1632C66.8919 98.9766 66.9399 98.7526 66.9399 98.4912V94.9792H68.2519V98.4432C68.2519 98.9606 68.1533 99.4086 67.9559 99.7872C67.7586 100.166 67.4706 100.459 67.0919 100.667C66.7133 100.87 66.2466 100.971 65.6919 100.971Z"
          fill="#EEF3FD"
        />
        <path
          d="M70.5749 100.859V99.8352H71.9429C72.1882 99.8352 72.3722 99.7712 72.4949 99.6432C72.6175 99.5152 72.6789 99.3339 72.6789 99.0992C72.6789 98.8646 72.6175 98.6859 72.4949 98.5632C72.3722 98.4352 72.1882 98.3712 71.9429 98.3712H70.5749V97.5792H71.8789C72.2842 97.5792 72.6442 97.6299 72.9589 97.7312C73.2735 97.8272 73.5215 97.9846 73.7029 98.2032C73.8842 98.4219 73.9749 98.7179 73.9749 99.0912V99.2032C73.9749 99.5499 73.8949 99.8486 73.7349 100.099C73.5749 100.345 73.3402 100.534 73.0309 100.667C72.7215 100.795 72.3375 100.859 71.8789 100.859H70.5749ZM69.4549 100.859V94.9312H70.7349V100.859H69.4549ZM70.5749 98.1632V97.3792H71.7269C71.9775 97.3792 72.1589 97.3152 72.2709 97.1872C72.3829 97.0592 72.4389 96.8886 72.4389 96.6752C72.4389 96.4512 72.3829 96.2752 72.2709 96.1472C72.1589 96.0192 71.9775 95.9552 71.7269 95.9552H70.5749V94.9312H71.6469C72.3189 94.9312 72.8335 95.0726 73.1909 95.3552C73.5535 95.6379 73.7349 96.0406 73.7349 96.5632V96.6832C73.7349 97.0459 73.6415 97.3366 73.4549 97.5552C73.2735 97.7739 73.0255 97.9312 72.7109 98.0272C72.4015 98.1179 72.0469 98.1632 71.6469 98.1632H70.5749Z"
          fill="#EEF3FD"
        />
        <path
          d="M76.9869 100.971C76.4589 100.971 76.0135 100.889 75.6509 100.723C75.2882 100.553 75.0135 100.323 74.8269 100.035C74.6402 99.7419 74.5469 99.4059 74.5469 99.0272H75.8189C75.8189 99.1766 75.8589 99.3152 75.9389 99.4432C76.0242 99.5712 76.1522 99.6752 76.3229 99.7552C76.4935 99.8299 76.7149 99.8672 76.9869 99.8672C77.2322 99.8672 77.4375 99.8352 77.6029 99.7712C77.7682 99.7072 77.8935 99.6192 77.9789 99.5072C78.0642 99.3899 78.1069 99.2566 78.1069 99.1072C78.1069 98.9206 78.0269 98.7739 77.8669 98.6672C77.7069 98.5552 77.4482 98.4832 77.0909 98.4512L76.6509 98.4112C76.0695 98.3632 75.6055 98.1819 75.2589 97.8672C74.9122 97.5526 74.7389 97.1366 74.7389 96.6192C74.7389 96.2459 74.8269 95.9232 75.0029 95.6512C75.1842 95.3792 75.4349 95.1712 75.7549 95.0272C76.0749 94.8779 76.4535 94.8032 76.8909 94.8032C77.3495 94.8032 77.7415 94.8832 78.0669 95.0432C78.3975 95.1979 78.6509 95.4192 78.8269 95.7072C79.0029 95.9899 79.0909 96.3232 79.0909 96.7072H77.8109C77.8109 96.5632 77.7762 96.4326 77.7069 96.3152C77.6375 96.1926 77.5335 96.0939 77.3949 96.0192C77.2615 95.9446 77.0935 95.9072 76.8909 95.9072C76.6989 95.9072 76.5362 95.9392 76.4029 96.0032C76.2749 96.0672 76.1789 96.1552 76.1149 96.2672C76.0509 96.3739 76.0189 96.4912 76.0189 96.6192C76.0189 96.7846 76.0775 96.9286 76.1949 97.0512C76.3122 97.1739 76.5042 97.2459 76.7709 97.2672L77.2189 97.3072C77.6455 97.3446 78.0215 97.4379 78.3469 97.5872C78.6722 97.7312 78.9255 97.9312 79.1069 98.1872C79.2935 98.4379 79.3869 98.7446 79.3869 99.1072C79.3869 99.4806 79.2882 99.8086 79.0909 100.091C78.8989 100.369 78.6242 100.585 78.2669 100.739C77.9095 100.894 77.4829 100.971 76.9869 100.971Z"
          fill="#EEF3FD"
        />
        <path
          d="M82.9708 100.971C82.4428 100.971 81.9895 100.881 81.6108 100.699C81.2321 100.513 80.9228 100.27 80.6828 99.9712C80.4428 99.6672 80.2641 99.3419 80.1468 98.9952C80.0348 98.6432 79.9788 98.3019 79.9788 97.9712V97.7952C79.9788 97.4326 80.0375 97.0752 80.1548 96.7232C80.2721 96.3659 80.4508 96.0432 80.6908 95.7552C80.9361 95.4672 81.2428 95.2379 81.6108 95.0672C81.9841 94.8912 82.4241 94.8032 82.9308 94.8032C83.4588 94.8032 83.9255 94.9019 84.3308 95.0992C84.7361 95.2966 85.0588 95.5712 85.2988 95.9232C85.5441 96.2699 85.6881 96.6779 85.7308 97.1472H84.4108C84.3735 96.9126 84.2881 96.7126 84.1548 96.5472C84.0215 96.3819 83.8481 96.2539 83.6348 96.1632C83.4268 96.0726 83.1921 96.0272 82.9308 96.0272C82.6695 96.0272 82.4375 96.0726 82.2348 96.1632C82.0321 96.2539 81.8615 96.3819 81.7228 96.5472C81.5895 96.7126 81.4855 96.9099 81.4108 97.1392C81.3415 97.3632 81.3068 97.6139 81.3068 97.8912C81.3068 98.1632 81.3415 98.4139 81.4108 98.6432C81.4855 98.8672 81.5921 99.0646 81.7308 99.2352C81.8748 99.4006 82.0508 99.5286 82.2588 99.6192C82.4668 99.7046 82.7041 99.7472 82.9708 99.7472C83.3761 99.7472 83.7175 99.6486 83.9948 99.4512C84.2775 99.2539 84.4481 98.9819 84.5068 98.6352H85.8188C85.7708 99.0619 85.6268 99.4539 85.3868 99.8112C85.1521 100.163 84.8295 100.446 84.4188 100.659C84.0135 100.867 83.5308 100.971 82.9708 100.971Z"
          fill="#EEF3FD"
        />
        <path
          d="M86.6893 100.819V94.9312H88.0013V100.819H86.6893ZM90.2332 100.819L88.5213 98.3072H89.9613L91.7533 100.819H90.2332ZM87.6493 98.9472V97.9152H89.0893C89.2812 97.9152 89.4466 97.8779 89.5853 97.8032C89.7293 97.7232 89.8386 97.6139 89.9132 97.4752C89.9932 97.3312 90.0332 97.1659 90.0332 96.9792C90.0332 96.7926 89.9932 96.6299 89.9132 96.4912C89.8386 96.3472 89.7293 96.2379 89.5853 96.1632C89.4466 96.0832 89.2812 96.0432 89.0893 96.0432H87.6493V94.9312H88.9853C89.4706 94.9312 89.8919 95.0059 90.2492 95.1552C90.6066 95.2992 90.8812 95.5152 91.0732 95.8032C91.2653 96.0912 91.3612 96.4539 91.3612 96.8912V97.0192C91.3612 97.4512 91.2626 97.8112 91.0653 98.0992C90.8732 98.3819 90.5986 98.5952 90.2412 98.7392C89.8892 98.8779 89.4706 98.9472 88.9853 98.9472H87.6493Z"
          fill="#EEF3FD"
        />
        <path
          d="M92.549 100.819V94.9792H93.861V100.819H92.549Z"
          fill="#EEF3FD"
        />
        <path
          d="M96.3092 100.859V99.8352H97.6772C97.9226 99.8352 98.1066 99.7712 98.2292 99.6432C98.3519 99.5152 98.4132 99.3339 98.4132 99.0992C98.4132 98.8646 98.3519 98.6859 98.2292 98.5632C98.1066 98.4352 97.9226 98.3712 97.6772 98.3712H96.3092V97.5792H97.6133C98.0186 97.5792 98.3786 97.6299 98.6932 97.7312C99.0079 97.8272 99.2559 97.9846 99.4372 98.2032C99.6186 98.4219 99.7092 98.7179 99.7092 99.0912V99.2032C99.7092 99.5499 99.6292 99.8486 99.4692 100.099C99.3092 100.345 99.0746 100.534 98.7653 100.667C98.4559 100.795 98.0719 100.859 97.6133 100.859H96.3092ZM95.1892 100.859V94.9312H96.4692V100.859H95.1892ZM96.3092 98.1632V97.3792H97.4613C97.7119 97.3792 97.8932 97.3152 98.0052 97.1872C98.1172 97.0592 98.1732 96.8886 98.1732 96.6752C98.1732 96.4512 98.1172 96.2752 98.0052 96.1472C97.8932 96.0192 97.7119 95.9552 97.4613 95.9552H96.3092V94.9312H97.3812C98.0532 94.9312 98.5679 95.0726 98.9252 95.3552C99.2879 95.6379 99.4692 96.0406 99.4692 96.5632V96.6832C99.4692 97.0459 99.3759 97.3366 99.1892 97.5552C99.0079 97.7739 98.7599 97.9312 98.4452 98.0272C98.1359 98.1179 97.7812 98.1632 97.3812 98.1632H96.3092Z"
          fill="#EEF3FD"
        />
        <path
          d="M100.728 100.819V94.9792H102.008V100.819H100.728ZM101.848 100.819V99.7312H104.4V100.819H101.848ZM101.848 98.3952V97.3072H104.216V98.3952H101.848ZM101.848 96.0672V94.9792H104.344V96.0672H101.848Z"
          fill="#EEF3FD"
        />
        <rect
          x="33.5"
          y="65.8193"
          width="90"
          height="3"
          rx="1.5"
          fill="#8750E1"
        />
        <rect
          x="33.5"
          y="71.8193"
          width="70"
          height="3"
          rx="1.5"
          fill="#8750E1"
        />
        <rect
          x="33.5"
          y="59.8193"
          width="48"
          height="3"
          rx="1.5"
          fill="#8750E1"
        />
      </g>
      <rect
        x="20"
        y="16.3193"
        width="121"
        height="142"
        rx="3.5"
        stroke="#F3F3F4"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_1629_22043"
        x="0.5"
        y="0.819336"
        width="160"
        height="181"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="9.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1629_22043"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1629_22043"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_1629_22043">
        <rect
          x="19.5"
          y="15.8193"
          width="122"
          height="143"
          rx="4"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);
