import MainActions from './mainActions';
import ButtonUndoAndRedo from './ButtonUndoAndRedo';
import ButtonZoom from './ButtonZoom';

export default function CanvasControls(props) {
  const { stateUndo, setMiniMap } = props;
  return (
    <>
      <ButtonUndoAndRedo
        {...{
          stateUndo,
        }}
      />
      <MainActions {...{ stateUndo }} />
      <ButtonZoom {...{ setMiniMap }} />
    </>
  );
}
