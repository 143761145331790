export const linkedIn = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <path
      d="M27.2302 27.0867H22.4949V19.7442C22.4949 17.9933 22.4634 15.7393 20.0321 15.7393C17.5658 15.7393 17.1885 17.647 17.1885 19.6167V27.0862H12.4532V11.9869H16.9991V14.0504H17.0627C17.5176 13.2802 18.175 12.6466 18.9649 12.2171C19.7547 11.7876 20.6474 11.5783 21.5479 11.6114C26.3473 11.6114 27.2322 14.7371 27.2322 18.8035L27.2302 27.0867ZM7.11028 9.92296C6.56679 9.92306 6.03546 9.76358 5.58352 9.46468C5.13157 9.16579 4.77931 8.74091 4.57123 8.24377C4.36316 7.74664 4.30863 7.19957 4.41456 6.67176C4.52049 6.14394 4.78213 5.65908 5.16637 5.2785C5.5506 4.89791 6.04019 4.63868 6.57322 4.5336C7.10625 4.42852 7.65876 4.48231 8.16092 4.68815C8.66307 4.894 9.0923 5.24266 9.39433 5.69005C9.69636 6.13744 9.85762 6.66347 9.85772 7.2016C9.85779 7.55891 9.78676 7.91274 9.64873 8.24287C9.51069 8.57301 9.30835 8.87299 9.05323 9.12569C8.79811 9.37839 8.49519 9.57886 8.16182 9.71566C7.82845 9.85245 7.47114 9.9229 7.11028 9.92296ZM9.4779 27.0867H4.73772V11.9869H9.4779V27.0867ZM29.5909 0.127156H2.35826C1.74016 0.120249 1.14454 0.356603 0.702342 0.784279C0.260145 1.21196 0.00754363 1.79596 0 2.40797V29.4843C0.00728544 30.0966 0.259739 30.681 0.701921 31.1091C1.1441 31.5372 1.73985 31.7741 2.35826 31.7675H29.5909C30.2106 31.7752 30.808 31.5391 31.2519 31.111C31.6958 30.6829 31.95 30.0978 31.9585 29.4843V2.40602C31.9497 1.7928 31.6954 1.20812 31.2515 0.780458C30.8075 0.352792 30.2103 0.117114 29.5909 0.125201"
      fill="#0A66C2"
    />
  </svg>
);
