export const initialNodes = [
  {
    id: 'ac6efe19-aa24-4d4f-bf5f-39aae6e81ecb',
    position: {
      x: 162,
      y: 213,
    },
    type: 'canvasElement',
    data: {
      type: 'keywordPlanner',
      title: 'Twitter',
      icon: 'twitter',
      backgroundColor: '#fff',
    },
    width: 44,
    height: 44,
    selected: false,
    dragging: false,
    positionAbsolute: {
      x: 162,
      y: 213,
    },
  },
  {
    id: 'b04a52b1-53e8-41ac-b644-565f79fefe8d',
    position: {
      x: 161,
      y: 97,
    },
    type: 'canvasElement',
    data: {
      type: 'socialPlan',
      title: 'Social Plan',
      icon: 'socialPlan',
      backgroundColor: '#FB5E9B',
    },
    width: 44,
    height: 44,
    selected: false,
    positionAbsolute: {
      x: 161,
      y: 97,
    },
    dragging: false,
  },
  {
    id: 'bcd85450-5de7-42f3-b8d0-e2240c435162',
    position: {
      x: 166,
      y: 327,
    },
    type: 'canvasElement',
    data: {
      type: 'adSet',
      title: 'Ad Set',
      icon: 'adSet',
      backgroundColor: '#3485F4',
    },
    width: 44,
    height: 44,
    selected: false,
    positionAbsolute: {
      x: 166,
      y: 327,
    },
    dragging: false,
  },
  {
    id: '7416c96c-3eca-42be-8bce-d1990c03d4f5',
    position: {
      x: 885,
      y: 122,
    },
    type: 'canvasPage',
    data: {
      type: 'optIn',
      title: 'Opt In',
      icon: 'optIn',
    },
    width: 165,
    height: 190,
    selected: false,
    positionAbsolute: {
      x: 885,
      y: 122,
    },
    dragging: false,
  },
  {
    id: 'e6f4990d-2829-44e7-9dfc-3fb404982c41',
    position: {
      x: 465,
      y: 133,
    },
    type: 'canvasPage',
    data: {
      type: 'landingPage',
      title: 'Landing Page',
      icon: 'landingPage',
    },
    width: 165,
    height: 190,
    selected: false,
    positionAbsolute: {
      x: 465,
      y: 133,
    },
    dragging: false,
  },
  {
    id: '82278fb4-5c82-4107-b2dc-aa01eb8f5fb1',
    position: {
      x: 869,
      y: 372,
    },
    type: 'canvasElement',
    data: {
      type: 'blogPlanner',
      title: 'Blog Planner',
      icon: 'blogPlanner',
      backgroundColor: '#1CCCA6',
    },
    width: 44,
    height: 44,
    selected: false,
    positionAbsolute: {
      x: 869,
      y: 372,
    },
    dragging: false,
  },
  {
    id: '659a2b48-3185-4d03-9a3b-77f667d12102',
    position: {
      x: 1384,
      y: 296,
    },
    type: 'canvasPage',
    data: {
      type: 'salesPage',
      title: 'Sales Page',
      icon: 'salesPage',
    },
    width: 165,
    height: 190,
    selected: false,
    positionAbsolute: {
      x: 1384,
      y: 296,
    },
    dragging: false,
  },
  {
    id: '5326a0ee-f51e-4d4c-b444-1a5ef1cb3104',
    position: {
      x: 271,
      y: 426,
    },
    type: 'stickyNotes',
    style: {
      width: 183,
      height: 175,
    },
    width: 183,
    height: 175,
    selected: false,
    positionAbsolute: {
      x: 271,
      y: 426,
    },
    dragging: false,
    resizing: false,
  },
  {
    id: 'b6b25ae0-98e6-468f-b89d-e7494675bc72',
    position: {
      x: 694,
      y: 48.599999999999994,
    },
    type: 'stickyNotes',
    style: {
      width: 100,
      height: 100,
    },
    width: 100,
    height: 100,
    resizing: false,
    selected: false,
    positionAbsolute: {
      x: 694,
      y: 48.599999999999994,
    },
    dragging: false,
  },
  {
    id: '59c3de2d-d973-4035-ab4f-5f93ebb3edf3',
    position: {
      x: 1254,
      y: 174,
    },
    type: 'canvasElementCondition',
    data: {
      type: 'condition',
      title: 'Condition',
      icon: 'condition',
      backgroundColor: '#417CF0',
    },
    width: 44,
    height: 44,
    positionAbsolute: {
      x: 1254,
      y: 174,
    },
    selected: false,
    dragging: false,
  },
  {
    id: '9b6e9cb0-208c-40b0-9ab5-9b6f32333763',
    position: {
      x: 1081,
      y: 430,
    },
    type: 'canvasElement',
    data: {
      type: 'timer',
      title: 'Wait (Timer)',
      icon: 'timer',
      backgroundColor: '#F95959',
    },
    width: 44,
    height: 44,
    selected: true,
    positionAbsolute: {
      x: 1081,
      y: 430,
    },
    dragging: false,
  },
];
export const initialEdges = [
  {
    animated: true,
    markerEnd: {
      type: 'arrowclosed',
      width: 20,
      height: 20,
      color: 'var(--Primary-600, #5E21C0)',
    },
    style: {
      stroke: 'var(--Primary-600, #5E21C0)',
      strokeWidth: 1,
    },
    source: 'ac6efe19-aa24-4d4f-bf5f-39aae6e81ecb',
    sourceHandle: 'right',
    target: 'e6f4990d-2829-44e7-9dfc-3fb404982c41',
    targetHandle: 'left',
    type: 'withLabel',
    data: {
      typePath: 'default',
      typeData: 'leads',
      count: {
        leads: 500,
        visitors: 1500,
        seals: 2000,
      },
    },
    id: 'reactflow__edge-ac6efe19-aa24-4d4f-bf5f-39aae6e81ecbright-e6f4990d-2829-44e7-9dfc-3fb404982c41left',
  },
  {
    animated: true,
    markerEnd: {
      type: 'arrowclosed',
      width: 20,
      height: 20,
      color: 'var(--Primary-600, #5E21C0)',
    },
    style: {
      stroke: 'var(--Primary-600, #5E21C0)',
      strokeWidth: 1,
    },
    source: 'b04a52b1-53e8-41ac-b644-565f79fefe8d',
    sourceHandle: 'right',
    target: 'e6f4990d-2829-44e7-9dfc-3fb404982c41',
    targetHandle: 'left',
    type: 'withLabel',
    data: {
      typePath: 'default',
      typeData: 'seals',
      count: {
        leads: 500,
        visitors: 1500,
        seals: 2000,
      },
    },
    id: 'reactflow__edge-b04a52b1-53e8-41ac-b644-565f79fefe8dright-e6f4990d-2829-44e7-9dfc-3fb404982c41left',
    selected: false,
  },
  {
    animated: true,
    markerEnd: {
      type: 'arrowclosed',
      width: 20,
      height: 20,
      color: 'var(--Primary-600, #5E21C0)',
    },
    style: {
      stroke: 'var(--Primary-600, #5E21C0)',
      strokeWidth: 1,
    },
    source: 'bcd85450-5de7-42f3-b8d0-e2240c435162',
    sourceHandle: 'right',
    target: 'e6f4990d-2829-44e7-9dfc-3fb404982c41',
    targetHandle: 'left',
    type: 'withLabel',
    data: {
      typePath: 'default',
      typeData: 'visitors',
      count: {
        leads: 500,
        visitors: 1500,
        seals: 2000,
      },
    },
    id: 'reactflow__edge-bcd85450-5de7-42f3-b8d0-e2240c435162right-e6f4990d-2829-44e7-9dfc-3fb404982c41left',
    selected: false,
  },
  {
    animated: false,
    markerEnd: {
      type: 'arrowclosed',
      width: 20,
      height: 20,
      color: 'var(--Primary-600, #5E21C0)',
    },
    style: {
      stroke: 'var(--Primary-600, #5E21C0)',
      strokeWidth: 1,
    },
    source: 'e6f4990d-2829-44e7-9dfc-3fb404982c41',
    sourceHandle: 'right',
    target: '82278fb4-5c82-4107-b2dc-aa01eb8f5fb1',
    targetHandle: 'left',
    type: 'withLabel',
    data: {
      typePath: 'default',
      typeData: 'visitors',
      count: {
        leads: 500,
        visitors: 1500,
        seals: 2000,
      },
    },
    id: 'reactflow__edge-e6f4990d-2829-44e7-9dfc-3fb404982c41right-82278fb4-5c82-4107-b2dc-aa01eb8f5fb1left',
    selected: false,
    markerStart: {},
  },
  {
    animated: false,
    markerEnd: {
      type: 'arrowclosed',
      width: 20,
      height: 20,
      color: 'var(--Primary-600, #5E21C0)',
    },
    style: {
      stroke: 'var(--Primary-600, #5E21C0)',
      strokeWidth: 1,
    },
    source: 'e6f4990d-2829-44e7-9dfc-3fb404982c41',
    sourceHandle: 'right',
    target: '7416c96c-3eca-42be-8bce-d1990c03d4f5',
    targetHandle: 'left',
    type: 'withLabel',
    data: {
      typePath: 'default',
      typeData: 'leads',
      count: {
        leads: 500,
        visitors: 1500,
        seals: 2000,
      },
    },
    id: 'reactflow__edge-e6f4990d-2829-44e7-9dfc-3fb404982c41right-7416c96c-3eca-42be-8bce-d1990c03d4f5left',
    selected: false,
  },
  {
    animated: true,
    markerEnd: {
      type: 'arrowclosed',
      width: 20,
      height: 20,
      color: 'var(--Primary-600, #5E21C0)',
    },
    style: {
      stroke: 'var(--Primary-600, #5E21C0)',
      strokeWidth: 1,
    },
    source: '7416c96c-3eca-42be-8bce-d1990c03d4f5',
    sourceHandle: 'right',
    target: '59c3de2d-d973-4035-ab4f-5f93ebb3edf3',
    targetHandle: 'top',
    type: 'withLabel',
    data: {
      typePath: 'smoothstep',
      typeData: 'leads',
      count: {
        leads: 500,
        visitors: 1500,
        seals: 2000,
      },
    },
    id: 'reactflow__edge-7416c96c-3eca-42be-8bce-d1990c03d4f5right-59c3de2d-d973-4035-ab4f-5f93ebb3edf3top',
  },
  {
    animated: true,
    markerEnd: {
      type: 'arrowclosed',
      width: 20,
      height: 20,
      color: 'var(--Primary-600, #5E21C0)',
    },
    style: {
      stroke: 'var(--System-Warning-600, #5E21C0)',
    },
    source: '59c3de2d-d973-4035-ab4f-5f93ebb3edf3',
    sourceHandle: 'no',
    target: '9b6e9cb0-208c-40b0-9ab5-9b6f32333763',
    targetHandle: 'top',
    type: 'withLabel',
    data: {
      typePath: 'default',
      typeData: 'leads',
      count: {
        leads: 500,
        visitors: 1500,
        seals: 2000,
      },
    },
    id: 'reactflow__edge-59c3de2d-d973-4035-ab4f-5f93ebb3edf3no-9b6e9cb0-208c-40b0-9ab5-9b6f32333763top',
  },
  {
    animated: true,
    markerEnd: {
      type: 'arrowclosed',
      width: 20,
      height: 20,
      color: 'var(--Primary-600, #5E21C0)',
    },
    style: {
      stroke: 'var(--System-Success-600, #5E21C0)',
    },
    source: '59c3de2d-d973-4035-ab4f-5f93ebb3edf3',
    sourceHandle: 'yes',
    target: '659a2b48-3185-4d03-9a3b-77f667d12102',
    targetHandle: 'top',
    type: 'withLabel',
    data: {
      typePath: 'default',
      typeData: 'leads',
      count: {
        leads: 500,
        visitors: 1500,
        seals: 2000,
      },
    },
    id: 'reactflow__edge-59c3de2d-d973-4035-ab4f-5f93ebb3edf3yes-659a2b48-3185-4d03-9a3b-77f667d12102top',
  },
];
