import React, { useEffect, useState } from 'react';
import { IconCustom } from '../../../ui/icon';
import styles from './SideBarTitle.module.css';
import { useNodes, useReactFlow } from 'reactflow';
import CustomIconDropdown from './CustomIconDropdown';
import { getDefaultDataByNodeType } from '../../customNode/canvasElements/getDefaultDataByNodeType';

export default function SideBarTitle({ id }) {
  const nodes = useNodes();
  const selectedNode = nodes.find((node) => node.id === id) ?? {};
  const { title, icon, backgroundColor } = selectedNode.data ?? {};
  const [value, setValue] = useState(title);
  useEffect(() => {
    setValue(title);

    return () => {
      setValue('');
    };
  }, [title]);

  const { setNodes } = useReactFlow();
  const saveNewTitle = () => {
    setNodes((nodes) => {
      const updateNodes = nodes.map((node) => {
        if (node.id === id) {
          return { ...node, data: { ...node?.data, title: value } };
        }
        return node;
      });
      return updateNodes;
    });
  };

  const defaultData = getDefaultDataByNodeType(selectedNode?.data?.type);

  return (
    <div className={styles.root}>
      <div className={styles.dropdownButton}>
        <div
          style={{
            backgroundColor,
            zIndex: 2,
          }}
          className={styles.iconBox}
        >
          <IconCustom name={icon} backgroundColor={backgroundColor} />
        </div>
        <CustomIconDropdown {...{ id, defaultData }} />
      </div>

      <input
        className={styles.input}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onBlur={saveNewTitle}
      />
    </div>
  );
}
