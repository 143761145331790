export const lineStraight = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.41381 11.6386L11.5862 4.36143M11.5862 4.36143V7.64832M11.5862 4.36143H8.20917M13.3333 2.77825H13.2794M2.72063 13.3339H2.66673"
      stroke="currentColor"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
