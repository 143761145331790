import { hocCanvasElementsWithNode } from './hocCanvasElementsWithNode';

import CanvasElementsBuilder from './CanvasElementsBuilder';
import CanvasPagesBuilder from './CanvasPagesBuilder';
import { hocCanvasElementsConditionWithNode } from './hocCanvasElementsConditionWithNode';
const CanvasElementsBuilderNode = hocCanvasElementsWithNode(
  CanvasElementsBuilder,
);
const CanvasElementConditionBuilderNode = hocCanvasElementsConditionWithNode(
  CanvasElementsBuilder,
);

const CanvasPagesBuilderNode = hocCanvasElementsWithNode(CanvasPagesBuilder);

export {
  CanvasElementsBuilder,
  CanvasElementsBuilderNode,
  CanvasElementConditionBuilderNode,
  CanvasPagesBuilder,
  CanvasPagesBuilderNode,
};
