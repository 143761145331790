import React, { useEffect, useState } from 'react';
import styles from './SideBar.module.css';
import { useNodes } from 'reactflow';
import { IconButton } from '../../../ui';
import SideBarTitle from './SideBarTitle';

export default function SideBar({ isOpenSideBar, setIsOpenSideBar }) {
  const [selectedNodeId, setSelectedNodeId] = useState();

  const nodes = useNodes();

  useEffect(() => {
    if (!isOpenSideBar) {
      return;
    }

    setSelectedNodeId(() => {
      const selectedNodeId = nodes.find((node) => node.selected)?.id;
      return selectedNodeId;
    });

    return () => {
      setSelectedNodeId();
    };
    // open only when isOpenSideBar is true
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenSideBar]);

  return (
    <div className={`${styles.root} ${isOpenSideBar && 'open'}`}>
      {selectedNodeId && (
        <div className={styles.content}>
          <SideBarTitle
            {...{
              id: selectedNodeId,
            }}
          />
        </div>
      )}
      <IconButton
        className={styles.button}
        name={'close'}
        onClick={() => setIsOpenSideBar((s) => !s)}
      ></IconButton>
    </div>
  );
}
