import React from 'react';
import styles from './CanvasElements.module.css';
import { getDefaultDataByNodeType } from './getDefaultDataByNodeType';
import PageCuspomView from './PageCuspomView';
import IconPage from '../../../ui/icon/IconPage';

export default function CanvasPagesBuilder({
  type,
  onClick,
  title,
  titleOff,
  view,
  onDragStart,
}) {
  const currentTitle = title ?? getDefaultDataByNodeType(type).title;
  const draggable = onDragStart ? true : false;
  const cursor = onDragStart && 'grab';
  return (
    <div
      className={styles.root}
      onDragStart={onDragStart}
      draggable={draggable}
    >
      <div className={styles.page} onClick={onClick} style={{ cursor }}>
        {view ? (
          <PageCuspomView />
        ) : (
          <IconPage name={getDefaultDataByNodeType(type).icon} />
        )}
      </div>
      {!titleOff && <p className={styles.title}>{currentTitle}</p>}
    </div>
  );
}
