import React from 'react';
import { Dropdown, Icon, IconButton } from '../../ui';
import { useReactFlow } from 'reactflow';
import styles from './LabelEdge.module.css';

const typeEdge = [
  { id: 'visitors', icon: 'web_traffic' },
  { id: 'leads', icon: 'person' },
  { id: 'seals', icon: 'request_quote' },
];

export default function LabelEdge({ id, data, labelX, labelY }) {
  const { setEdges } = useReactFlow();

  const icon = typeEdge.find((type) => type.id === data?.typeData)?.icon;
  return (
    <div
      className={`nodrag nopan ${styles.root}`}
      style={{
        transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
      }}
    >
      <div className={styles.icon}>
        <Icon name={icon} className={styles.svg} />
      </div>
      <div className={styles.content}>
        <div className={styles.number}>
          {data?.count?.[data?.typeData] ?? 1500}
        </div>
        <div className={styles.text}>{data?.typeData}</div>
      </div>
      <Dropdown className={`edgebutton ${styles.dropdown}`}>
        {typeEdge.map((type) => (
          <IconButton
            key={type.id}
            icon={type.icon}
            onClick={() => {
              setEdges((edges) =>
                edges.map((edge) =>
                  edge.id === id
                    ? { ...edge, data: { ...edge.data, typeData: type.id } }
                    : edge,
                ),
              );
            }}
          />
        ))}
      </Dropdown>
    </div>
  );
}
