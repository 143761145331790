import { Controls } from 'reactflow';
import styles from '../CanvasControls.module.css';
import { useCallback, useRef, useState } from 'react';
import Dialog from '../../../../ui/dialog';
import ControlButtonWrapper from './ControlButtonWrapper';
import DialogContent from './DialogContent';
import ButtonStickyNotes from './ButtonStickyNotes';

const buttons = [
  { id: 'templates', label: 'Templates', icon: 'template' },
  { id: 'page', label: 'Page Elements', icon: 'pageTemplate' },
  { id: 'tools', label: 'Campaign Tools', icon: 'campaignTools' },
  { id: 'actions', label: 'Actions', icon: 'moreApps' },
];

export default function MainActions() {
  const dialogRef = useRef(null);
  const [dialogType, setDialogType] = useState(undefined);

  const onDragStart = (event, canvasType, type) => {
    event.dataTransfer.setData(
      'application/marketplan',
      `${canvasType}_${type}`,
    );
    event.dataTransfer.effectAllowed = 'move';
  };

  const openDialog = () => {
    dialogRef?.current.show();
  };
  const closeDialog = useCallback(() => {
    dialogRef?.current.close();
    setDialogType(undefined);
  }, [dialogRef]);

  const onClick = (id) => {
    const isOpen = dialogRef?.current?.open;
    if (!isOpen) {
      setDialogType(id);
      openDialog();
      return;
    }
    if (id !== dialogType) {
      setDialogType(id);
      openDialog();
      return;
    }
    closeDialog();
  };

  return (
    <Controls
      {...{ showZoom: false, showFitView: false, showInteractive: false }}
      position="bottom-center"
      className={styles.root}
    >
      {buttons.map(({ label, icon, id }) => (
        <ControlButtonWrapper
          key={label}
          active={dialogType === id}
          {...{ id, label, icon, onClick: () => onClick(id) }}
        />
      ))}
      <ButtonStickyNotes {...{ onDragStart }} />

      <Dialog {...{ dialogRef, close: closeDialog }}>
        <DialogContent {...{ type: dialogType, onDragStart }} />
      </Dialog>
    </Controls>
  );
}
