import {
  CanvasElementConditionBuilderNode,
  CanvasElementsBuilderNode,
  CanvasPagesBuilderNode,
} from './canvasElements';
import CustomNode from './CustomNode';
import StickyNotes from './stickyNotes';

export const nodeTypesPages = ['landingPage', 'optIn', 'salesPage'];
export const nodeTypesListTools = [
  'adSet',
  'socialPlan',
  'keywordPlanner',
  'blogPlanner',
  'email',
];
export const nodeTypesListAction = [
  'tag',
  // 'condition',
  'customAction',
  'timer',
  'customMedia',
  'planPortal',
];

export const nodeTypes = {
  customNode: CustomNode,
  stickyNotes: StickyNotes,
  canvasElement: CanvasElementsBuilderNode,
  canvasElementCondition: CanvasElementConditionBuilderNode,
  canvasPage: CanvasPagesBuilderNode,
};
