export const landingPage = (
  <svg
    width="161"
    height="182"
    viewBox="0 0 161 182"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_1629_21962)">
      <g clipPath="url(#clip0_1629_21962)">
        <rect
          x="19.5"
          y="15.8193"
          width="122"
          height="143"
          rx="4"
          fill="white"
        />
        <rect x="19.5" y="15.8193" width="122" height="13" fill="#F3EEFC" />
        <circle cx="26" cy="22.3193" r="2.5" fill="#939AA9" />
        <circle cx="34" cy="22.3193" r="2.5" fill="#939AA9" />
        <circle cx="42" cy="22.3193" r="2.5" fill="#939AA9" />
        <rect
          x="49.5"
          y="19.8193"
          width="86"
          height="5"
          rx="2.5"
          fill="white"
        />
        <rect
          x="31.5"
          y="40.8193"
          width="98"
          height="39"
          rx="2"
          fill="#E6DCF9"
        />
        <rect
          x="71.5"
          y="68.8193"
          width="17"
          height="5"
          rx="2.5"
          fill="#5E21C0"
        />
        <rect
          x="35.5"
          y="54.8193"
          width="90"
          height="3"
          rx="1.5"
          fill="white"
        />
        <rect
          x="45.5"
          y="60.8193"
          width="70"
          height="3"
          rx="1.5"
          fill="white"
        />
        <rect
          x="56.5"
          y="48.8193"
          width="48"
          height="3"
          rx="1.5"
          fill="white"
        />
        <rect
          x="35.5"
          y="97.8193"
          width="90"
          height="3"
          rx="1.5"
          fill="#E6DCF9"
        />
        <rect
          x="35.5"
          y="103.819"
          width="70"
          height="3"
          rx="1.5"
          fill="#E6DCF9"
        />
        <rect
          x="35.5"
          y="91.8193"
          width="48"
          height="3"
          rx="1.5"
          fill="#E6DCF9"
        />
        <rect
          x="31.5"
          y="118.819"
          width="98"
          height="27"
          rx="2"
          fill="#E6DCF9"
        />
        <rect
          x="71.5"
          y="130.819"
          width="17"
          height="5"
          rx="2.5"
          fill="#5E21C0"
        />
      </g>
      <rect
        x="20"
        y="16.3193"
        width="121"
        height="142"
        rx="3.5"
        stroke="#F3F3F4"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_1629_21962"
        x="0.5"
        y="0.819336"
        width="160"
        height="181"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="9.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1629_21962"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1629_21962"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_1629_21962">
        <rect
          x="19.5"
          y="15.8193"
          width="122"
          height="143"
          rx="4"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);
