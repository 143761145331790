export const youtube = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="19"
    viewBox="0 0 26 19"
    fill="none"
  >
    <path
      d="M25.4567 2.84921C25.1577 1.7271 24.2768 0.843485 23.158 0.543596C21.1305 -0.00141335 13 -0.00141335 13 -0.00141335C13 -0.00141335 4.86958 -0.00141335 2.84196 0.543596C1.72333 0.843485 0.842276 1.7271 0.543301 2.84921C0 4.88296 0 9.12625 0 9.12625C0 9.12625 0 13.3695 0.543301 15.4034C0.842276 16.5254 1.72333 17.409 2.84196 17.709C4.86958 18.2539 13 18.2539 13 18.2539C13 18.2539 21.1305 18.2539 23.158 17.709C24.2768 17.409 25.1577 16.5254 25.4567 15.4034C26 13.3695 26 9.12625 26 9.12625C26 9.12625 26 4.88296 25.4567 2.84921Z"
      fill="#ED1D24"
    />
    <path
      d="M10.3408 12.9805L17.1363 9.12808L10.3408 5.27533V12.9805Z"
      fill="white"
    />
  </svg>
);
