import React from 'react';
import { landingPage } from './iconsPage/landingPage';
import { optIn } from './iconsPage/optIn';
import { salesPage } from './iconsPage/salesPage';

export default function IconPage({ name }) {
  switch (name) {
    case 'landingPage':
      return <>{landingPage}</>;
    case 'optIn':
      return <>{optIn}</>;
    case 'salesPage':
      return <>{salesPage}</>;

    default:
      return <span>{name}</span>;
  }
}
