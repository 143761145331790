export const lineBezier = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.869 4.80623L9.17102 6.66695M10.869 4.80623L9.17102 3M10.869 4.80623L9.19171 4.91951C8.4577 4.96909 7.81017 5.41763 7.50574 6.08736L6.01692 9.36277C5.68608 10.0906 5.07837 10.6563 4.32871 10.9343L4.1514 11M13 4.74673H12.9463M2.05373 11H2"
      stroke="currentColor"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
