import Canvas from './Canvas';
import styles from './CanvasView.module.css';
import HeaderNav from '../headerNav';
import { ReactFlowProvider } from 'reactflow';
import AutoSizer from 'react-virtualized-auto-sizer';
import { useState } from 'react';
import SideBar from './sideBar/SideBar';

export default function CanvasView(props) {
  const [gridType, setGridType] = useState('dots');
  const [edgeType, setEdgeType] = useState('bezier');
  const [titleVisible, setTitleVisible] = useState('hover');
  const [isOpenSideBar, setIsOpenSideBar] = useState(false);
  const openSideBar = () => setIsOpenSideBar(true);
  return (
    <ReactFlowProvider>
      <div className={styles.root}>
        <HeaderNav
          {...{
            gridType,
            setGridType,
            edgeType,
            setEdgeType,
            titleVisible,
            setTitleVisible,
          }}
        />
        <div className={styles.canvas}>
          <AutoSizer>
            {({ width, height }) => (
              <div style={{ width, height }}>
                <Canvas
                  {...{
                    ...props,
                    variant: gridType,
                    edgeType,
                    titleVisible,
                    isOpenSideBar,
                    openSideBar,
                  }}
                />
              </div>
            )}
          </AutoSizer>
          <SideBar {...{ isOpenSideBar, setIsOpenSideBar }} />
        </div>
      </div>
    </ReactFlowProvider>
  );
}
