export const tiktok = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <path
      d="M16.0004 32.0008C24.8372 32.0008 32.0008 24.8372 32.0008 16.0004C32.0008 7.16362 24.8372 0 16.0004 0C7.16362 0 0 7.16362 0 16.0004C0 24.8372 7.16362 32.0008 16.0004 32.0008Z"
      fill="black"
    />
    <path
      d="M13.7738 13.3032V12.4299C13.4744 12.389 13.1702 12.3633 12.8595 12.3633C9.11909 12.3633 6.07617 15.4062 6.07617 19.1466C6.07617 21.4415 7.22319 23.4722 8.9722 24.7003C7.84285 23.4883 7.15014 21.8645 7.15014 20.0817C7.15014 16.3951 10.1072 13.3883 13.7738 13.3024V13.3032Z"
      fill="#25F4EE"
    />
    <path
      d="M13.9336 23.1822C15.6023 23.1822 16.9637 21.8546 17.0255 20.2011L17.0311 5.4312H19.7297C19.6735 5.12779 19.643 4.81556 19.643 4.49609H15.9571L15.9515 19.266C15.8897 20.9203 14.5284 22.2471 12.8596 22.2471C12.3411 22.2471 11.8523 22.1171 11.4229 21.8915C11.9863 22.6717 12.9006 23.183 13.9344 23.183L13.9336 23.1822Z"
      fill="#25F4EE"
    />
    <path
      d="M24.77 10.4441V9.62293C23.741 9.62293 22.7818 9.31711 21.9775 8.79297C22.6935 9.6149 23.6664 10.2081 24.77 10.4441Z"
      fill="#25F4EE"
    />
    <path
      d="M21.9778 8.79126C21.1936 7.89067 20.7168 6.71476 20.7168 5.42969H19.7295C19.9896 6.83356 20.8227 8.03836 21.9778 8.79126Z"
      fill="#FE2C55"
    />
    <path
      d="M12.859 16.0515C11.151 16.0515 9.76074 17.4409 9.76074 19.1498C9.76074 20.3394 10.4358 21.3732 11.4215 21.8917C11.0538 21.3829 10.8347 20.7592 10.8347 20.0849C10.8347 18.3769 12.2249 16.9866 13.933 16.9866C14.2517 16.9866 14.5575 17.0396 14.8473 17.1295V13.3674C14.5479 13.3265 14.2436 13.3008 13.933 13.3008C13.8792 13.3008 13.8263 13.304 13.7725 13.3048V16.1944C13.4835 16.1037 13.1769 16.0515 12.8582 16.0515H12.859Z"
      fill="#FE2C55"
    />
    <path
      d="M24.7708 10.4453V13.31C22.8596 13.31 21.0889 12.6984 19.6433 11.6614V19.1511C19.6433 22.8915 16.6004 25.9344 12.86 25.9344C11.4144 25.9344 10.0739 25.4785 8.97266 24.7047C10.212 26.0347 11.977 26.8695 13.9348 26.8695C17.6752 26.8695 20.7181 23.8266 20.7181 20.0862V12.5965C22.1637 13.6343 23.9344 14.2451 25.8456 14.2451V10.5593C25.4771 10.5593 25.1175 10.5192 24.7716 10.4453H24.7708Z"
      fill="#FE2C55"
    />
    <path
      d="M19.6431 19.1481V11.6584C21.0887 12.6962 22.8594 13.3071 24.7706 13.3071V10.4424C23.6669 10.2064 22.6941 9.6132 21.9781 8.79126C20.8231 8.03836 19.9899 6.83356 19.7298 5.42969H17.0312L17.0256 20.1996C16.9638 21.8539 15.6025 23.1807 13.9337 23.1807C12.8999 23.1807 11.9857 22.6702 11.4222 21.8892C10.4357 21.3699 9.76147 20.3369 9.76147 19.1473C9.76147 17.4392 11.1517 16.049 12.8598 16.049C13.1784 16.049 13.4843 16.102 13.774 16.1919V13.3023C10.1074 13.3881 7.15039 16.3949 7.15039 20.0816C7.15039 21.8651 7.84309 23.4881 8.97245 24.7002C10.0745 25.4739 11.4142 25.9298 12.8598 25.9298C16.6002 25.9298 19.6431 22.8869 19.6431 19.1465V19.1481Z"
      fill="white"
    />
  </svg>
);
