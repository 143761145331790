import { Handle, NodeResizer, Position, useNodeId } from 'reactflow';

export default function CustomNode() {
  const handleStyle = { left: 10 };
  const nodeId = useNodeId();
  return (
    <>
      <NodeResizer minWidth={30} minHeight={30} />
      <div
        style={{
          backgroundColor: 'red',
          outline: '1px solid teal',
          // padding: '10px',
          // width: `${size}px`,
          // height: `${size / 2}px`,
          // width: 'inherit',
          display: 'grid',
          placeItems: 'center',
          // flexGrow: 1,
          // flexShrink: 1,
          height: 'inherit',
          // resize: 'both',
          // overflow: 'auto',
        }}
      >
        <div style={{ padding: '10px', backgroundColor: 'turquoise' }}>
          <p style={{ margin: 0, padding: 0 }}>CustomNode Id: {nodeId}</p>
          <button>button1 </button>
          <button>button 2</button>
        </div>
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        id="a"
        style={{ left: '10px' }}
      />
      <Handle type="source" position={Position.Bottom} id="a2" />
      <Handle
        type="source"
        position={Position.Bottom}
        id="a3"
        style={{ left: '100px' }}
      />
      <Handle
        type="source"
        position={Position.Bottom}
        style={handleStyle}
        id="b"
      />
    </>
  );
}
