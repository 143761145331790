import { Position } from 'reactflow';
const positionList = [
  Position.Bottom,
  Position.Right,
  Position.Top,
  Position.Left,
];

export const getNextSinglePosition = (position) => {
  const index = positionList.indexOf(position);
  return positionList[index + 1] ?? positionList[0];
};

const getOppositePosition = (position) => {
  switch (position) {
    case Position.Top:
      return Position.Bottom;
    case Position.Right:
      return Position.Left;
    case Position.Bottom:
      return Position.Top;
    case Position.Left:
      return Position.Right;

    default:
      return Position.Top;
  }
};

export const getNextPosition = (position) => {
  const first = getNextSinglePosition(position?.first);
  const second = getOppositePosition(first);

  return { first, second };
};
