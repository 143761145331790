export const campaignTools = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M6.55091 7.30646V10.0688L13.4496 10.0719V12.8254M16.554 3.16724H3.4465C3.256 3.16724 3.10156 3.33571 3.10156 3.54353V6.93017C3.10156 7.13799 3.256 7.30646 3.4465 7.30646H16.554C16.7445 7.30646 16.899 7.13799 16.899 6.93017V3.54353C16.899 3.33571 16.7445 3.16724 16.554 3.16724ZM16.554 12.8254H3.4465C3.256 12.8254 3.10156 12.9939 3.10156 13.2017V16.5884C3.10156 16.7962 3.256 16.9646 3.4465 16.9646H16.554C16.7445 16.9646 16.899 16.7962 16.899 16.5884V13.2017C16.899 12.9939 16.7445 12.8254 16.554 12.8254Z"
      stroke="currentColor"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
