export const tumblr = (
  <svg
    width="15"
    height="26"
    viewBox="0 0 15 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Tumblr svg" clipPath="url(#clip0_4772_44075)">
      <path
        id="Vector"
        d="M10.3885 26C6.46829 26 3.56083 23.9925 3.56083 19.1777V11.4739H0V7.29567C3.92018 6.28374 5.55359 2.92153 5.73326 0H9.80044V6.62649H14.5373V11.4739H9.80044V18.182C9.80044 20.1896 10.8132 20.8914 12.4302 20.8914H14.7333V26H10.3885Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_4772_44075">
        <rect width="14.7333" height="26" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
