import React from 'react';
import {
  EdgeLabelRenderer,
  BaseEdge,
  getSimpleBezierPath,
  getStraightPath,
  getSmoothStepPath,
} from 'reactflow';
import LabelEdge from './LabelEdge';

const EdgeWithLabel = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  markerStart,
  markerEnd,
  style = {},
  data,
}) => {
  const getPath = () => {
    const typePath = data?.typePath;
    switch (typePath) {
      case 'smoothstep':
        return getSmoothStepPath({
          sourceX,
          sourceY,
          sourcePosition,
          targetX,
          targetY,
          targetPosition,
        });
      case 'straight':
        return getStraightPath({
          sourceX,
          sourceY,
          sourcePosition,
          targetX,
          targetY,
          targetPosition,
        });
      default:
        return getSimpleBezierPath({
          sourceX,
          sourceY,
          sourcePosition,
          targetX,
          targetY,
          targetPosition,
        });
    }
  };
  const [edgePath, labelX, labelY] = getPath();
  return (
    <>
      <BaseEdge
        id={id}
        path={edgePath}
        markerEnd={markerEnd}
        markerStart={markerStart}
        style={style}
      />
      <EdgeLabelRenderer>
        <LabelEdge id={id} data={data} labelX={labelX} labelY={labelY} />
      </EdgeLabelRenderer>
    </>
  );
};

export default EdgeWithLabel;
