import React from 'react';
import { initialEdges, initialNodes } from '../../../../templates';
import { useReactFlow } from 'reactflow';
import { Button } from '../../../../ui';

export default function ButtonTemplate({ id }) {
  const { setNodes, setEdges } = useReactFlow();

  const setTemplate = () => {
    setNodes(initialNodes);
    setEdges(initialEdges);
  };
  return <Button onClick={setTemplate}>Template {id}</Button>;
}
