import { ControlButton, Controls } from 'reactflow';
import styles from './CanvasControls.module.css';

export default function ButtonZoom({ setMiniMap }) {
  return (
    <Controls position="bottom-right" className={styles.root}>
      <ControlButton onClick={() => setMiniMap((s) => !s)}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
        >
          <path d="m600-120-240-84-186 72q-20 8-37-4.5T120-170v-560q0-13 7.5-23t20.5-15l212-72 240 84 186-72q20-8 37 4.5t17 33.5v560q0 13-7.5 23T812-192l-212 72Zm-40-98v-468l-160-56v468l160 56Zm80 0 120-40v-474l-120 46v468Zm-440-10 120-46v-468l-120 40v474Zm440-458v468-468Zm-320-56v468-468Z" />
        </svg>
      </ControlButton>
    </Controls>
  );
}
