export const stickyNotes = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M16.379 11.8803V2.62598H3.62207V17.375H11.1637M16.379 11.8803L11.1637 17.375M16.379 11.8803H11.1637V17.375"
      stroke="currentColor"
      strokeWidth="1.6"
      strokeLinejoin="round"
    />
  </svg>
);
