export const getDefaultDataByNodeType = (type) => {
  switch (type) {
    //elements start
    case 'adSet':
      return {
        title: 'Ad Set',
        icon: 'adSet',
        backgroundColor: '#3485F4',
      };
    case 'socialPlan':
      return {
        title: 'Social Plan',
        icon: 'socialPlan',
        backgroundColor: '#FB5E9B',
      };
    case 'keywordPlanner':
      return {
        title: 'Keyword Planner',
        icon: 'keywordPlanner',
        backgroundColor: '#84CCFD',
      };
    case 'blogPlanner':
      return {
        title: 'Blog Planner',
        icon: 'blogPlanner',
        backgroundColor: '#1CCCA6',
      };
    case 'email':
      return {
        title: 'Email',
        icon: 'email',
        backgroundColor: '#61DC82',
      };
    case 'tag':
      return {
        title: 'Tag',
        icon: 'tag',
        backgroundColor: '#FF7A2F',
      };
    case 'condition':
      return {
        title: 'Condition',
        icon: 'condition',
        backgroundColor: '#417CF0',
      };
    case 'customAction':
      return {
        title: 'Custom Action',
        icon: 'customAction',
        backgroundColor: '#17CDD6',
      };
    case 'timer':
      return {
        title: 'Wait (Timer)',
        icon: 'timer',
        backgroundColor: '#F95959',
      };
    case 'customMedia':
      return {
        title: 'Custom Media',
        icon: 'customMedia',
        backgroundColor: '#7367F0',
      };
    case 'planPortal':
      return {
        title: 'Plan Portal',
        icon: 'planPortal',
        backgroundColor: '#526CFC',
      };
    //elements end

    //pages start
    case 'landingPage':
      return {
        title: 'Landing Page',
        icon: 'landingPage',
      };
    case 'optIn':
      return {
        title: 'Opt In',
        icon: 'optIn',
      };
    case 'salesPage':
      return {
        title: 'Sales Page',
        icon: 'salesPage',
      };
    //pages end

    default:
      return {
        title: 'type not found',
        icon: undefined,
        backgroundColor: '#000',
      };
  }
};
