export const moreApps = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M14.0196 10.3983V13.9491M14.0196 13.9491V17.5M14.0196 13.9491H17.5M14.0196 13.9491H10.6772M5.63072 2.5L2.5 7.9953H8.59822L5.63072 2.5ZM16.6537 2.55169L11.3083 2.55085V7.98022H16.6537V2.55169ZM8.8547 13.9492C8.8547 15.6128 7.46522 16.9614 5.75121 16.9614C4.0372 16.9614 2.64772 15.6128 2.64772 13.9492C2.64772 12.2855 4.0372 10.9369 5.75121 10.9369C7.46522 10.9369 8.8547 12.2855 8.8547 13.9492Z"
      stroke="currentColor"
      strokeWidth="1.6"
      strokeLinejoin="round"
    />
  </svg>
);
