import React from 'react';
import styles from './CanvasElements.module.css';
import IconCustom from '../../../ui/icon/IconCustom';
import { getDefaultDataByNodeType } from './getDefaultDataByNodeType';
import { IconMaterial } from '../../../ui/icon';

export default function CanvasElementsBuilder({
  type,
  onClick,
  backgroundColor,
  title,
  titleOff,
  icon,
  onDragStart,
}) {
  const currentIcon = icon ?? getDefaultDataByNodeType(type).icon;
  const [materialIcon, iconType] = currentIcon.split('&');
  const currentBackgroundColor =
    backgroundColor ?? getDefaultDataByNodeType(type).backgroundColor;
  const currentTitle = title ?? getDefaultDataByNodeType(type).title;
  const draggable = onDragStart ? true : false;
  const cursor = onDragStart && 'grab';
  return (
    <div
      className={styles.root}
      onDragStart={onDragStart}
      draggable={draggable}
    >
      <div
        className={styles.icon}
        style={{
          backgroundColor: currentBackgroundColor,
          cursor,
        }}
        onClick={onClick}
      >
        {iconType === 'material' ? (
          <IconMaterial name={materialIcon} style={{ color: '#fff' }} />
        ) : (
          <IconCustom name={currentIcon} />
        )}
      </div>
      {!titleOff && <p className={styles.title}>{currentTitle}</p>}
    </div>
  );
}
