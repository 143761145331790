export const planPortal = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M18.4215 16.6191L18.4218 4.77424C18.4218 3.68393 17.5349 2.80005 16.441 2.80005H7.52718C6.43322 2.80005 5.54639 3.6839 5.54638 4.77419L5.50295 17.317C5.49915 18.4142 6.38005 19.3096 7.47718 19.3237L18.4961 19.4657M9.01302 6.74835H14.9554M9.01302 9.70958H14.9554M9.01302 12.6708H11.9842M18.4961 19.4657L15.4767 17.5118M18.4961 19.4657L15.3187 21.3"
      stroke="white"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
