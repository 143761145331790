export const customMedia = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M10.1819 14.6125L7.59676 12.0274L10.1819 9.44222M14.3182 9.44222L16.9033 12.0274L14.3182 14.6125M6.04566 20.2999C4.90347 20.2999 3.97754 19.3739 3.97754 18.2318V5.82301C3.97754 4.68081 4.90347 3.75488 6.04566 3.75488H18.4544C19.5966 3.75488 20.5225 4.68081 20.5225 5.82301V18.2318C20.5225 19.3739 19.5966 20.2999 18.4544 20.2999H6.04566Z"
      stroke="white"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
