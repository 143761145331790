import React from 'react';
import { getBezierPath, useStore } from 'reactflow';

export default function ConnectionLine({
  fromX,
  fromY,
  toX,
  toY,
  fromPosition,
  toPosition,
}) {
  const { connectionHandleId } = useStore();

  const [path] = getBezierPath({
    sourceX: fromX,
    sourceY: fromY,
    sourcePosition: fromPosition,
    targetX: toX,
    targetY: toY,
    targetPosition: toPosition,
  });
  const stroke =
    connectionHandleId === 'yes'
      ? 'var(--System-Success-600)'
      : connectionHandleId === 'no'
      ? 'var(--System-Warning-600)'
      : 'var(--Grey-600)';

  return (
    <g>
      <path
        fill="none"
        stroke={stroke}
        strokeWidth={1.5}
        className="animated"
        d={path}
      />
      <circle
        cx={toX}
        cy={toY}
        fill="#fff"
        r={3}
        stroke={stroke}
        strokeWidth={1.5}
      />
    </g>
  );
}
