import React from 'react';

export default function RoundRect({ width, height, ...svgAttributes }) {
  const rounding = Math.min(20, 0.2 * Math.min(width, height));
  return (
    <rect
      x={0}
      y={0}
      rx={rounding}
      width={width}
      height={height}
      {...svgAttributes}
    />
  );
}
