import { MarkerType } from 'reactflow';

export const gridTypes = [
  { label: 'dots', value: 'dots' },
  { label: 'lines', value: 'lines' },
  { label: 'cross', value: 'cross' },
];

export const defaultEdgeOptionsStyle = {
  stroke: 'var(--Primary-600, #5E21C0)',
  strokeWidth: 1,
};

export const defaultEdgeOptions = {
  animated: true,
  markerEnd: {
    type: MarkerType.ArrowClosed,
    width: 20,
    height: 20,
    color: 'var(--Primary-600, #5E21C0)',
  },
  style: defaultEdgeOptionsStyle,
};
