import { v4 as uuidv4 } from 'uuid';
import { getDefaultDataByNodeType } from './canvasElements/getDefaultDataByNodeType';

export const createNewNode = (canvasType, type, position) => {
  const randomId = uuidv4();
  let data = undefined;
  if (
    canvasType === 'canvasPage' ||
    canvasType === 'canvasElement' ||
    canvasType === 'canvasElementCondition'
  ) {
    data = {
      type,
      ...getDefaultDataByNodeType(type),
    };
  }
  let style = undefined;
  if (canvasType === 'stickyNotes') {
    style = {
      width: 200,
      height: 200,
    };
  }

  const newNode = {
    id: randomId,
    position: position,
    type: canvasType,
    data,
    style,
  };
  return newNode;
};
