import React from 'react';
import { adSet } from './iconsLib/adSet';
import { keywordPlanner } from './iconsLib/keywordPlanner';
import { socialPlan } from './iconsLib/socialPlan';
import { blogPlanner } from './iconsLib/blogPlanner';
import { email } from './iconsLib/email';
import { stickyNotes } from './iconsLib/stickyNotes';
import { campaignTools } from './iconsLib/campaignTools';
import { moreApps } from './iconsLib/moreApps';
import { pageTemplate } from './iconsLib/pageTemplate';
import { template } from './iconsLib/template';
import { tag } from './iconsLib/tag';
import { condition } from './iconsLib/condition';
import { customAction } from './iconsLib/customAction';
import { timer } from './iconsLib/timer';
import { customMedia } from './iconsLib/customMedia';
import { planPortal } from './iconsLib/planPortal';
import { youtube } from './iconsLib/youtube';
import { twitter } from './iconsLib/twitter';
import { tumblr } from './iconsLib/tumblr';
import { tiktok } from './iconsLib/tiktok';
import { reddit } from './iconsLib/reddit';
import { pinterest } from './iconsLib/pinterest';
import { linkedIn } from './iconsLib/linkedIn';
import { instagram } from './iconsLib/instagram';
import { lineBezier } from './lineBezier';
import { lineSmoothstep } from './lineSmoothstep';
import { lineStraight } from './lineStraight';

export default function IconCustom({ name }) {
  switch (name) {
    case 'adSet':
      return <>{adSet}</>;
    case 'socialPlan':
      return <>{socialPlan}</>;
    case 'keywordPlanner':
      return <>{keywordPlanner}</>;
    case 'blogPlanner':
      return <>{blogPlanner}</>;
    case 'email':
      return <>{email}</>;
    case 'template':
      return <>{template}</>;
    case 'pageTemplate':
      return <>{pageTemplate}</>;
    case 'campaignTools':
      return <>{campaignTools}</>;
    case 'moreApps':
      return <>{moreApps}</>;
    case 'stickyNotes':
      return <>{stickyNotes}</>;
    case 'tag':
      return <>{tag}</>;
    case 'condition':
      return <>{condition}</>;
    case 'customAction':
      return <>{customAction}</>;
    case 'timer':
      return <>{timer}</>;
    case 'customMedia':
      return <>{customMedia}</>;
    case 'planPortal':
      return <>{planPortal}</>;
    case 'youtube':
      return <>{youtube}</>;
    case 'twitter':
      return <>{twitter}</>;
    case 'tumblr':
      return <>{tumblr}</>;
    case 'tiktok':
      return <>{tiktok}</>;
    case 'reddit':
      return <>{reddit}</>;
    case 'pinterest':
      return <>{pinterest}</>;
    case 'linkedIn':
      return <>{linkedIn}</>;
    case 'instagram':
      return <>{instagram}</>;
    case 'lineBezier':
      return <>{lineBezier}</>;
    case 'lineSmoothstep':
      return <>{lineSmoothstep}</>;
    case 'lineStraight':
      return <>{lineStraight}</>;

    default:
      return <span>{name}</span>;
  }
}
