import React, { useState } from 'react';
import { Button, Dropdown } from '../../../ui';

export default function PlanningMode() {
  const [mode, setMode] = useState('mode1');
  return (
    <Dropdown label={`Planning ${mode}`}>
      <Button
        label={'Planning mode 1'}
        onClick={() => setMode('mode1')}
        active={mode === 'mode1'}
      />
      <Button
        label={'Planning mode 2'}
        onClick={() => setMode('mode2')}
        active={mode === 'mode2'}
      />
    </Dropdown>
  );
}
