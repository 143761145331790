import EdgeWithLabel from './EdgeWithLabel';
// import SimpleFloatingEdge from './SimpleFloatingEdge';

const edgeTypes = {
  withLabel: EdgeWithLabel,

  // SimpleFloatingEdge:
  // it's not change the edge sorse and target position.
  // it's only change the edge path
  // floating: SimpleFloatingEdge,
};
const edgeTypesButton = [
  { label: 'Bezier', value: 'bezier' },
  // { label: 'withLabel', value: 'withLabel' },
  // { label: 'floating', value: 'floating' },
  { label: 'Straight', value: 'straight' },
  // { label: 'step', value: 'step' },
  { label: 'Smoothstep', value: 'smoothstep' },
];

export { edgeTypes, edgeTypesButton };
