export const pinterest = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <path
      d="M16 0C7.16379 0 0 7.16379 0 16C0 22.7819 4.21399 28.5761 10.1663 30.907C10.0214 29.6428 9.90288 27.6938 10.2189 26.3111C10.5086 25.0601 12.0889 18.3572 12.0889 18.3572C12.0889 18.3572 11.6148 17.3959 11.6148 15.9868C11.6148 13.7613 12.9053 12.1021 14.5119 12.1021C15.8815 12.1021 16.5399 13.1292 16.5399 14.3539C16.5399 15.7235 15.6708 17.7778 15.2099 19.6872C14.828 21.2807 16.0132 22.5844 17.5802 22.5844C20.4247 22.5844 22.6107 19.5819 22.6107 15.2626C22.6107 11.4305 19.8584 8.7572 15.921 8.7572C11.3646 8.7572 8.69136 12.1679 8.69136 15.6971C8.69136 17.0667 9.21811 18.5416 9.87654 19.3449C10.0082 19.5029 10.0214 19.6477 9.98189 19.8058C9.86337 20.3062 9.58683 21.3992 9.53416 21.623C9.46831 21.9128 9.29712 21.9786 8.99424 21.8337C6.99259 20.8988 5.74156 17.9885 5.74156 15.6313C5.74156 10.5877 9.40247 5.95226 16.316 5.95226C21.8601 5.95226 26.1794 9.90288 26.1794 15.1967C26.1794 20.7144 22.7029 25.1523 17.8831 25.1523C16.2634 25.1523 14.7358 24.3095 14.2222 23.3086C14.2222 23.3086 13.4189 26.3638 13.2214 27.1144C12.8658 28.5103 11.8914 30.2486 11.2329 31.3152C12.7342 31.7761 14.3144 32.0263 15.9737 32.0263C24.8099 32.0263 31.9737 24.8626 31.9737 16.0263C32 7.16378 24.8362 0 16 0Z"
      fill="#E60023"
    />
  </svg>
);
