export const salesPage = (
  <svg
    width="161"
    height="182"
    viewBox="0 0 161 182"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_1629_22067)">
      <g clipPath="url(#clip0_1629_22067)">
        <rect
          x="19.5"
          y="15.8193"
          width="122"
          height="143"
          rx="4"
          fill="white"
        />
        <rect x="19.5" y="15.8193" width="122" height="13" fill="#F3EEFC" />
        <circle cx="26" cy="22.3193" r="2.5" fill="#939AA9" />
        <circle cx="34" cy="22.3193" r="2.5" fill="#939AA9" />
        <circle cx="42" cy="22.3193" r="2.5" fill="#939AA9" />
        <rect
          x="49.5"
          y="19.8193"
          width="86"
          height="5"
          rx="2.5"
          fill="white"
        />
        <rect
          x="31.5"
          y="39.8193"
          width="98"
          height="39"
          rx="2"
          fill="#F3EEFC"
        />
        <rect
          x="37.5"
          y="52.8193"
          width="85"
          height="3"
          rx="1.5"
          fill="#8750E1"
        />
        <rect
          x="37.5"
          y="46.8193"
          width="45.3333"
          height="3"
          rx="1.5"
          fill="#8750E1"
        />
        <rect
          x="37.5"
          y="64.8193"
          width="20"
          height="6"
          rx="3"
          fill="#5E21C0"
        />
        <rect
          x="84.5"
          y="103.819"
          width="42"
          height="3"
          rx="1.5"
          fill="#8750E1"
        />
        <rect
          x="84.5"
          y="97.8193"
          width="22.4"
          height="3"
          rx="1.5"
          fill="#8750E1"
        />
        <rect
          x="84.5"
          y="115.819"
          width="20"
          height="6"
          rx="3"
          fill="#5E21C0"
        />
        <rect
          x="31.5"
          y="89.8193"
          width="47"
          height="39"
          rx="2"
          fill="#DDCCFD"
        />
        <path
          d="M61.5 100.819H47.5C46.3954 100.819 45.5 101.715 45.5 102.819V116.819C45.5 117.924 46.3954 118.819 47.5 118.819H61.5C62.6046 118.819 63.5 117.924 63.5 116.819V102.819C63.5 101.715 62.6046 100.819 61.5 100.819Z"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M51 107.819C51.8284 107.819 52.5 107.148 52.5 106.319C52.5 105.491 51.8284 104.819 51 104.819C50.1716 104.819 49.5 105.491 49.5 106.319C49.5 107.148 50.1716 107.819 51 107.819Z"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M63.5 112.819L58.5 107.819L47.5 118.819"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect
          x="30.5"
          y="137.819"
          width="98"
          height="27"
          rx="2"
          fill="#F3EEFC"
        />
      </g>
      <rect
        x="20"
        y="16.3193"
        width="121"
        height="142"
        rx="3.5"
        stroke="#F3F3F4"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_1629_22067"
        x="0.5"
        y="0.819336"
        width="160"
        height="181"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="9.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1629_22067"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1629_22067"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_1629_22067">
        <rect
          x="19.5"
          y="15.8193"
          width="122"
          height="143"
          rx="4"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);
