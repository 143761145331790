import { useEdges, useNodes } from 'reactflow';
import { Button, Dropdown } from '../../ui';
import { gridTypes } from '../canvas/canvasVariables';
import styles from './HeaderNav.module.css';
import PlanningMode from './planningMode/PlanningMode';
import { edgeTypesButton } from '../customEdge';

// const titleVisibleOptions = [
//   { label: 'Hover', value: 'hover' },
//   { label: 'Always', value: 'always' },
//   { label: 'Never', value: 'never' },
// ];

export default function HeaderNav({
  gridType,
  setGridType,
  edgeType,
  setEdgeType,
  titleVisible,
  setTitleVisible,
}) {
  const nodes = useNodes();
  const edges = useEdges();
  const saveTemplate = () => {
    console.log('saveTemplate', 'nodeData:', nodes);
    console.log('saveTemplate', 'edgeData:', edges);
  };

  return (
    <div className={styles.root}>
      <div className={styles.left}>
        <Button icon={'arrow_back'}></Button>
        <Dropdown icon="settings">
          <p>Grid Type:</p>
          <div style={{ display: 'flex', flexDirection: 'row', gap: '4px' }}>
            {gridTypes.map(({ label, value }) => (
              <Button
                key={value}
                onClick={() => setGridType(value)}
                label={label}
                active={value === gridType}
              ></Button>
            ))}
          </div>
          <p>Edge Type:</p>

          <div style={{ display: 'flex', flexDirection: 'row', gap: '4px' }}>
            {edgeTypesButton.map(({ label, value }) => (
              <Button
                key={value}
                onClick={() => setEdgeType(value)}
                label={label}
                active={value === edgeType}
              ></Button>
            ))}
          </div>
          {/* <p>label Visible:</p>
          <div style={{ display: 'flex', flexDirection: 'row', gap: '4px' }}>
            {titleVisibleOptions.map(({ label, value }) => (
              <Button
                key={value}
                onClick={() => setTitleVisible(value)}
                label={label}
                active={value === titleVisible}
              ></Button>
            ))}
          </div> */}
        </Dropdown>
      </div>
      <div className={styles.right}>
        <PlanningMode />
        <Button label={'Save'} onClick={saveTemplate} />
      </div>
    </div>
  );
}
