import React from 'react';
import { Handle, Position } from 'reactflow';
import styles from './Title.module.css';
// import { IconButton } from '../../../ui';

export function hocCanvasElementsWithNode(WrappedComponent) {
  return function ActionsNodeComponent(props) {
    const title = props?.data?.title;
    //todo useStore for titleVisible
    //error [React Flow]: It looks like you've created a new nodeTypes or edgeTypes object.
    const titleVisible = props?.titleVisible ?? 'always';

    return (
      <>
        <Handle
          type="source"
          className={styles.handle}
          position={Position.Right}
          id="right"
        />

        <Handle
          type="source"
          className={styles.handle}
          position={Position.Top}
          id="top"
        />
        <Handle
          type="source"
          className={styles.handle}
          position={Position.Left}
          id="left"
        />
        <Handle
          type="source"
          className={styles.handle}
          position={Position.Bottom}
          id="bottom"
        />
        <div style={{ padding: '2px' }}>
          <div className={styles.root}>
            {/* <div className={styles.settings}>
              <IconButton
                name={'more_vert'}
                onClick={() => console.log('first', props)}
              />
            </div> */}
            <WrappedComponent
              {...{ ...props, ...props?.data, titleOff: true }}
            />
            {titleVisible !== 'never' && (
              <p
                className={`${styles.title} ${
                  titleVisible === 'always' && 'always'
                }`}
              >
                {title}
              </p>
            )}
          </div>
        </div>
      </>
    );
  };
}
