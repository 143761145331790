export const lineSmoothstep = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.3689 5.06455L9.66578 6.98867M11.3689 5.06455L9.66578 3.19677M11.3689 5.06455H8.53898C7.20565 5.06455 7.20565 5.06455 7.20565 6.39788V9.46936C7.20565 11.4694 7.20565 11.4694 5.20565 11.4694H4.63107M13.5063 5.00301H13.4524M2.5271 11.4694H2.47321"
      stroke="currentColor"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
