export const keywordPlanner = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
  >
    <path
      d="M7.81884 7.33741H7.92486M14.068 9.73604L19.8842 15.5523C20.0718 15.7398 20.1771 15.9941 20.1771 16.2594V19.427C20.1771 19.9793 19.7294 20.427 19.1771 20.427H17.1226V18.1361H14.8317V15.8451L12.0099 13.1878C11.1032 13.8959 9.96216 14.3179 8.72254 14.3179C5.77032 14.3179 3.37707 11.9246 3.37707 8.9724C3.37707 6.02018 5.77032 3.62693 8.72254 3.62693C11.6748 3.62693 14.068 6.02018 14.068 8.9724V9.73604Z"
      stroke="#FCFCFC"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
