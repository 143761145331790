import {
  ControlButton,
  Controls,
  useEdges,
  useNodes,
  useReactFlow,
} from 'reactflow';
import styles from './CanvasControls.module.css';
import { useLayoutEffect, useRef } from 'react';

export default function ButtonUndoAndRedo({ stateUndo = { current: [] } }) {
  const { setEdges, setNodes } = useReactFlow();
  const edges = useEdges();
  const nodes = useNodes();
  const stateRedo = useRef([]);
  const count = useRef(stateUndo?.current.length);

  useLayoutEffect(() => {
    if (count.current < stateUndo?.current.length) {
      stateRedo.current = [];
    }
    count.current = stateUndo?.current.length;
  }, [stateUndo, stateUndo?.current.length]);

  const goUndo = () => {
    const step = stateUndo?.current[stateUndo?.current.length - 1];
    if (!step) {
      return;
    }
    stateUndo.current = stateUndo?.current.slice(0, -1);
    if (step.type === 'edges') {
      stateRedo.current = [
        ...stateRedo.current,
        { type: 'edges', data: edges },
      ];
      setEdges(step?.data);
      return;
    }
    if (step.type === 'nodes') {
      stateRedo.current = [
        ...stateRedo.current,
        { type: 'nodes', data: nodes },
      ];

      setNodes(step?.data);
      return;
    }
  };

  const goRedo = () => {
    count.current = count.current + 1;
    const step = stateRedo?.current[stateRedo?.current.length - 1];
    if (!step) {
      return;
    }
    stateRedo.current = stateRedo?.current.slice(0, -1);

    if (step.type === 'edges') {
      setEdges(step?.data);
      stateUndo.current = [
        ...stateUndo.current,
        { type: 'edges', data: edges },
      ];
      return;
    }
    if (step.type === 'nodes') {
      setNodes(step?.data);
      stateUndo.current = [
        ...stateUndo.current,
        { type: 'nodes', data: nodes },
      ];
      return;
    }
  };

  return (
    <Controls
      {...{ showZoom: false, showFitView: false, showInteractive: false }}
      position="bottom-left"
      className={styles.root}
    >
      <ControlButton disabled={!stateUndo.current.length} onClick={goUndo}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
        >
          <path d="M280-200v-80h284q63 0 109.5-40T720-420q0-60-46.5-100T564-560H312l104 104-56 56-200-200 200-200 56 56-104 104h252q97 0 166.5 63T800-420q0 94-69.5 157T564-200H280Z" />
        </svg>
      </ControlButton>
      <ControlButton disabled={!stateRedo.current.length} onClick={goRedo}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
        >
          <path d="M396-200q-97 0-166.5-63T160-420q0-94 69.5-157T396-640h252L544-744l56-56 200 200-200 200-56-56 104-104H396q-63 0-109.5 40T240-420q0 60 46.5 100T396-280h284v80H396Z" />
        </svg>
      </ControlButton>
    </Controls>
  );
}
