export const pageTemplate = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <g clipPath="url(#clip0_7247_68849)">
      <path
        d="M7.66634 1.33325H1.33301V8.66659H7.66634V1.33325Z"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinejoin="round"
      />
      <path
        d="M14.6663 11.3333H1.33301V14.3333H14.6663V11.3333Z"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinejoin="round"
      />
      <path
        d="M14.6663 1.33325H10.333V3.99992H14.6663V1.33325Z"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinejoin="round"
      />
      <path
        d="M14.6663 5.99992H10.333V8.66659H14.6663V5.99992Z"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_7247_68849">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
