import { useCallback, useRef, useState } from 'react';
import 'reactflow/dist/style.css';

import ReactFlow, {
  addEdge,
  applyNodeChanges,
  Background,
  ConnectionMode,
  useEdgesState,
  useNodesState,
  useReactFlow,
} from 'reactflow';
import { nodeTypes } from '../customNode/nodeTypes';
import CanvasControls from './сontrolsButton';
import DevTools from './devTools';
import EdgeContextMenu from './edgeContextMenu/EdgeContextMenu';
import NodeContextMenu from './nodeContextMenu';
import CanvasMimiMap from '../canvasMiniMap';
import { edgeTypes } from '../customEdge';

import { createNewNode } from '../customNode/utils';
import { defaultEdgeOptions, defaultEdgeOptionsStyle } from './canvasVariables';
import ConnectionLine from '../customEdge/ConnectionLine';
import { getHelperLines } from './utils';
import HelperLines from './HelperLines';

const initialContextMenuState = {
  type: '',
  isOpen: false,
  id: null,
  position: { top: 0, left: 0 },
};

export default function Canvas({
  variant = 'dots',
  initialNodes,
  initialEdges,
  edgeType,
  titleVisible,
  openSideBar,
  isOpenSideBar,
}) {
  const { screenToFlowPosition } = useReactFlow();
  const [miniMap, setMiniMap] = useState(false);

  const [contextMenuState, setContextMenuState] = useState(
    initialContextMenuState,
  );
  const closeContextMenu = () => setContextMenuState(initialContextMenuState);

  const stateUndo = useRef([]);
  const [nodes, setNodes] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

  const onConnect = useCallback(
    (params) => {
      setEdges((eds) => {
        stateUndo.current = [
          ...stateUndo.current,
          { type: 'edges', data: eds },
        ];
        console.log('params', params);
        // targetHandle;
        // sourceHandle;
        let style = defaultEdgeOptionsStyle;
        if (params.sourceHandle === 'yes' || params.targetHandle === 'yes') {
          style = { stroke: 'var(--System-Success-600, #5E21C0)' };
        }
        if (params.sourceHandle === 'no' || params.targetHandle === 'no') {
          style = { stroke: 'var(--System-Warning-600, #5E21C0)' };
        }
        return addEdge(
          {
            ...params,
            type: 'withLabel',
            //test data
            data: {
              typePath: edgeType,
              typeData: 'leads',
              count: { leads: 500, visitors: 1500, seals: 2000 },
            },
            style,
          },
          eds,
        );
      });
    },
    [setEdges, edgeType],
  );

  const onEdgeContextMenu = useCallback((e, edge) => {
    e.preventDefault();
    setContextMenuState({
      type: 'edge',
      isOpen: true,
      id: edge.id,
      position: { top: e.clientY, left: e.clientX },
    });
  }, []);

  const onNodeContextMenu = useCallback((e, node) => {
    e.preventDefault();
    setContextMenuState({
      type: 'node',
      isOpen: true,
      id: node.id,
      position: { top: e.clientY, left: e.clientX },
    });
  }, []);

  const onNodeDragStart = () => {
    stateUndo.current = [...stateUndo.current, { type: 'nodes', data: nodes }];
  };

  //todo what is this?
  const onEdgeUpdateStart = () => {
    console.log('onEdgeUpdateStart');
    stateUndo.current = [...stateUndo.current, { type: 'edges', data: edges }];
  };

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();

      const data = event.dataTransfer.getData('application/marketplan');
      // check if the dropped element is valid
      if (typeof data === 'undefined' || !data) {
        return;
      }
      const [canvasType, type] = data?.split('_');

      const position = screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
      });
      const newNode = createNewNode(canvasType, type, position);
      setNodes((nodes) => {
        stateUndo.current = [
          ...stateUndo.current,
          { type: 'nodes', data: nodes },
        ];
        return [...nodes, newNode];
      });
    },
    [screenToFlowPosition, setNodes],
  );

  const [helperLineHorizontal, setHelperLineHorizontal] = useState();
  const [helperLineVertical, setHelperLineVertical] = useState();
  const customApplyNodeChanges = useCallback((changes, nodes) => {
    // reset the helper lines (clear existing lines, if any)
    setHelperLineHorizontal(undefined);
    setHelperLineVertical(undefined);

    // this will be true if it's a single node being dragged
    // inside we calculate the helper lines and snap position for the position where the node is being moved to
    if (
      changes.length === 1 &&
      changes[0].type === 'position' &&
      changes[0].dragging &&
      changes[0].position
    ) {
      const helperLines = getHelperLines(changes[0], nodes);

      // if we have a helper line, we snap the node to the helper line position
      // this is being done by manipulating the node position inside the change object
      changes[0].position.x =
        helperLines.snapPosition.x ?? changes[0].position.x;
      changes[0].position.y =
        helperLines.snapPosition.y ?? changes[0].position.y;

      // if helper lines are returned, we set them so that they can be displayed
      setHelperLineHorizontal(helperLines.horizontal);
      setHelperLineVertical(helperLines.vertical);
    }

    return applyNodeChanges(changes, nodes);
  }, []);

  const onNodesChange = useCallback(
    (changes) => {
      setNodes((nodes) => customApplyNodeChanges(changes, nodes));
    },
    [setNodes, customApplyNodeChanges],
  );

  return (
    <>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodeDragStart={onNodeDragStart}
        onEdgeUpdateStart={onEdgeUpdateStart}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        onEdgeContextMenu={onEdgeContextMenu}
        onNodeContextMenu={onNodeContextMenu}
        onNodeDoubleClick={openSideBar}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        onDrop={onDrop}
        onDragOver={onDragOver}
        defaultEdgeOptions={defaultEdgeOptions}
        connectionMode={ConnectionMode.Loose}
        connectionLineComponent={ConnectionLine}
      >
        <CanvasControls
          {...{
            stateUndo,
            setMiniMap,
          }}
        />
        <HelperLines
          horizontal={helperLineHorizontal}
          vertical={helperLineVertical}
        />
        {miniMap && <CanvasMimiMap />}
        <Background {...{ variant, gap: 12, size: 1 }} />

        <DevTools />
      </ReactFlow>
      <EdgeContextMenu
        {...{
          stateUndo,
          contextMenu: contextMenuState,
          closeMenu: closeContextMenu,
        }}
      />
      <NodeContextMenu
        {...{
          stateUndo,
          contextMenu: contextMenuState,
          closeMenu: closeContextMenu,
          openSideBar,
          isOpenSideBar,
        }}
      />
    </>
  );
}
