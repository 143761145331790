import React, { useState } from 'react';
import styles from './CustomIconDropdown.module.css';
import { IconMaterial } from '../../../ui/icon';

import ListIconMaterial from './ListIconMaterial';
import ListIconPreset from './ListIconPreset';
import { Button } from '../../../ui';

export default function CustomIconDropdownContent({ id, defaultData }) {
  const [part, setPart] = useState('preset');
  const transformStyle =
    part === 'material'
      ? { transform: 'translateX(-50%)' }
      : { transform: 'translateX(0)' };

  return (
    <>
      <div className={styles.dropdownHeader}>
        <Button
          className={styles.headerButton}
          active={part === 'preset'}
          onClick={() => setPart('preset')}
        >
          <IconMaterial name={'chevron_left'} />
          Preset
        </Button>
        <Button
          className={styles.headerButton}
          active={part === 'material'}
          onClick={() => setPart('material')}
        >
          Material
          <IconMaterial name={'chevron_right'} />
        </Button>
      </div>
      <div className={styles.dropdownBody} style={transformStyle}>
        <ListIconPreset {...{ id, defaultData }} />
        <ListIconMaterial {...{ id }} />
      </div>
    </>
  );
}
