import React, { useEffect, useRef, useState } from 'react';

import Button from '../button';
import styles from './Dropdown.module.css';

const iconOpen = 'keyboard_arrow_down';
const iconClose = 'keyboard_arrow_up';

export default function Dropdown({
  children,
  style,
  styleContent,
  className,
  classNameContent,

  label,

  outSideClose = true,
  borderless,
  containerless,

  disabled,
  icon,
}) {
  const [isOpen, setIsOpen] = useState(false);

  const dropdownRef = useRef();

  useEffect(() => {
    const dropHandleOutside = (e) => {
      if (!outSideClose) return;
      if (!isOpen) return;
      if (!dropdownRef.current) return;
      if (dropdownRef.current?.contains(e.target)) {
        return;
      }
      isOpen && setIsOpen(false);
    };
    document.addEventListener('click', dropHandleOutside);
    return () => {
      document.removeEventListener('click', dropHandleOutside);
    };
  }, [isOpen, outSideClose, dropdownRef]);

  const classContent = `
    ${styles.content}
    ${!borderless && styles.border}
    ${!containerless && styles.container}
    ${classNameContent}`;

  const curIcon = icon ? icon : isOpen ? iconClose : iconOpen;

  const onHandleClick = () => {
    setIsOpen((s) => !s);
  };
  return (
    <div
      className={`${styles.dropdown} ${className}`}
      ref={dropdownRef}
      style={style}
    >
      <Button
        style={{ width: '100%' }}
        // active={isOpen}
        disabled={disabled}
        icon={curIcon}
        onClick={onHandleClick}
        label={label}
      />

      {isOpen && (
        <div className={classContent} style={styleContent}>
          {children}
        </div>
      )}
    </div>
  );
}
