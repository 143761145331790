export const reddit = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <path
      d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
      fill="#FF4500"
    />
    <path
      d="M26.667 15.9991C26.667 14.7078 25.619 13.6599 24.3278 13.6599C23.6916 13.6599 23.1302 13.9032 22.7185 14.3149C21.1278 13.1733 18.9196 12.4248 16.4869 12.3312L17.5535 7.33475L21.0155 8.06457C21.053 8.9441 21.7828 9.65521 22.681 9.65521C23.598 9.65521 24.3465 8.90668 24.3465 7.98972C24.3465 7.07276 23.598 6.32422 22.681 6.32422C22.0261 6.32422 21.4647 6.69849 21.2027 7.25989L17.329 6.4365C17.2167 6.41779 17.1044 6.4365 17.0109 6.49264C16.9173 6.54878 16.8612 6.64235 16.8237 6.75463L15.6448 12.3312C13.1559 12.4061 10.929 13.1359 9.31963 14.3149C8.90794 13.9219 8.32782 13.6599 7.71027 13.6599C6.41905 13.6599 5.37109 14.7078 5.37109 15.9991C5.37109 16.9535 5.9325 17.7581 6.75589 18.1324C6.71846 18.357 6.69975 18.6002 6.69975 18.8435C6.69975 22.4365 10.8728 25.3371 16.0378 25.3371C21.2027 25.3371 25.3758 22.4365 25.3758 18.8435C25.3758 18.6002 25.3571 18.3757 25.3196 18.1511C26.0869 17.7769 26.667 16.9535 26.667 15.9991ZM10.667 17.6646C10.667 16.7476 11.4155 15.9991 12.3325 15.9991C13.2495 15.9991 13.998 16.7476 13.998 17.6646C13.998 18.5815 13.2495 19.3301 12.3325 19.3301C11.4155 19.3301 10.667 18.5815 10.667 17.6646ZM19.9676 22.0622C18.8261 23.2038 16.6553 23.2786 16.019 23.2786C15.3828 23.2786 13.1933 23.185 12.0705 22.0622C11.9021 21.8938 11.9021 21.6131 12.0705 21.4447C12.2389 21.2763 12.5196 21.2763 12.6881 21.4447C13.3992 22.1558 14.9337 22.4178 16.0378 22.4178C17.1419 22.4178 18.6576 22.1558 19.3875 21.4447C19.5559 21.2763 19.8366 21.2763 20.005 21.4447C20.136 21.6318 20.136 21.8938 19.9676 22.0622ZM19.6682 19.3301C18.7512 19.3301 18.0027 18.5815 18.0027 17.6646C18.0027 16.7476 18.7512 15.9991 19.6682 15.9991C20.5851 15.9991 21.3337 16.7476 21.3337 17.6646C21.3337 18.5815 20.5851 19.3301 19.6682 19.3301Z"
      fill="white"
    />
  </svg>
);
