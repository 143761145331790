import { useStore } from 'reactflow';
import Rectangle from './Rectangle';
import RoundRect from './RoundRect';

function MiniMapNode({ id, width, height, x, y, selected }) {
  // get the node data to render the shape accordingly
  const { backgroundColor = '#e2e2e2', type } = useStore((state) => {
    const item = state.nodeInternals.get(id);
    const { backgroundColor } = item?.data ?? {};
    const type = item?.type;
    return { backgroundColor, type };
  });

  const ShapeComponents = {
    stickyNotes: Rectangle,
    canvasElement: Rectangle,
    canvasPage: RoundRect,
  };
  const ShapeComponent = ShapeComponents[type];
  const color =
    type === 'canvasPage'
      ? 'var(--Charts-Purple-400, #B7ADED)'
      : type === 'stickyNotes'
      ? 'var(--Charts-Yellow-500, #fdd655)'
      : backgroundColor;

  return (
    <g transform={`translate(${x}, ${y})`}>
      <ShapeComponent
        width={width}
        height={height}
        fill={color}
        strokeWidth={selected ? 6 : 0}
        className={
          selected
            ? 'react-flow__minimap-node selected'
            : 'react-flow__minimap-node'
        }
      />
    </g>
  );
}

export default MiniMapNode;
