import { NodeResizeControl } from 'reactflow';
import styles from './StickyNotes.module.css';
import { useState } from 'react';
import ResizeIcon from './ResizeIcon';

export default function StickyNotes() {
  const [value, setValue] = useState('');

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  return (
    <>
      <NodeResizeControl
        style={{ background: 'transparent', border: 'none' }}
        minWidth={100}
        minHeight={100}
        maxWidth={400}
        maxHeight={400}
      >
        <ResizeIcon />
      </NodeResizeControl>
      <textarea
        className={styles.textarea}
        placeholder="Add text"
        value={value}
        onChange={handleChange}
      />
    </>
  );
}
