import React from 'react';
import styles from './CustomIconDropdown.module.css';
import { IconCustom } from '../../../ui/icon';
import Divider from '../../../ui/divider/Divider';
import { Button } from '../../../ui';
import { useReactFlow } from 'reactflow';

const iconListPreset = [
  // {
  //   id: 'facebook',
  //   icon: 'facebook',
  //   backgroundColor: '#fff',
  //   title: 'Facebook',
  // },
  {
    id: 'instagram',
    icon: 'instagram',
    backgroundColor: '#fff',
    title: 'Instagram',
  },
  {
    id: 'linkedIn',
    icon: 'linkedIn',
    backgroundColor: '#fff',
    title: 'LinkedIn',
  },
  {
    id: 'pinterest',
    icon: 'pinterest',
    backgroundColor: '#fff',
    title: 'Pinterest',
  },
  { id: 'reddit', icon: 'reddit', backgroundColor: '#fff', title: 'Reddit' },
  { id: 'tiktok', icon: 'tiktok', backgroundColor: '#fff', title: 'Tiktok' },
  { id: 'tumblr', icon: 'tumblr', backgroundColor: '#fff', title: 'Tumblr' },
  { id: 'twitter', icon: 'twitter', backgroundColor: '#fff', title: 'Twitter' },
  { id: 'youtube', icon: 'youtube', backgroundColor: '#fff', title: 'Youtube' },
];

export default function ListIconPreset({ id, defaultData }) {
  const { setNodes } = useReactFlow();

  const useDefaultIcon = () => {
    setNodes((nodes) => {
      const updateNodes = nodes.map((node) => {
        if (node.id === id) {
          return {
            ...node,
            data: {
              ...node?.data,
              icon: defaultData?.icon,
              backgroundColor: defaultData?.backgroundColor,
            },
          };
        }
        return node;
      });
      return updateNodes;
    });
  };
  const saveNewIcon = (iconId) => {
    const { icon, backgroundColor, title } =
      iconListPreset.find(({ id }) => id === iconId) ?? {};
    setNodes((nodes) => {
      const updateNodes = nodes.map((node) => {
        if (node.id === id) {
          return {
            ...node,
            data: { ...node?.data, icon, backgroundColor, title },
          };
        }
        return node;
      });
      return updateNodes;
    });
  };

  return (
    <div className={styles.listWrapper}>
      <Divider>Default Icon:</Divider>
      {defaultData && (
        <Button className={styles.defaultBox} onClick={useDefaultIcon}>
          <div
            className={styles.defaultIcon}
            style={{ background: defaultData?.backgroundColor }}
          >
            <IconCustom name={defaultData?.icon} />
          </div>
          <div>{defaultData?.title}</div>
        </Button>
      )}
      <Divider>Preset Icons:</Divider>
      {iconListPreset.map(({ id, icon, backgroundColor, title }) => (
        <Button
          key={id}
          className={styles.defaultBox}
          onClick={() => saveNewIcon(id)}
        >
          <div
            className={styles.defaultIcon}
            style={{ background: backgroundColor }}
          >
            <IconCustom name={icon} />
          </div>
          <div>{title}</div>
        </Button>
      ))}
    </div>
  );
}
