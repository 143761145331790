import React from 'react';
import styles from './Button.module.css';
import Icon from '../icon';

export default function Button({
  onClick,
  label,
  icon,
  leftIcon,
  style,
  color,
  className,
  large,
  disabled,
  active,
  children,
  draggable,
  onDragStart,
}) {
  const handleClick = (event) => {
    typeof onClick === 'function' && onClick(event);
  };
  const handleDragStart = (event) => {
    typeof onDragStart === 'function' && onDragStart(event);
  };

  return (
    <button
      className={`${className} ${styles.root} f ${styles.label} 
      ${active && 'active'}`}
      style={{ ...style, color }}
      onClick={handleClick}
      type="button"
      disabled={disabled}
      draggable={draggable}
      onDragStart={handleDragStart}
    >
      {children ? (
        children
      ) : (
        <>
          {icon && !!leftIcon && <Icon name={icon} />}

          {label && <span>{label}</span>}
          {icon && !leftIcon && <Icon name={icon} />}
        </>
      )}
    </button>
  );
}
