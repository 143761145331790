export const template = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M8.3 8C8.3 7.55817 7.94183 7.2 7.5 7.2C7.05817 7.2 6.7 7.55817 6.7 8H8.3ZM6.7 18C6.7 18.4418 7.05817 18.8 7.5 18.8C7.94183 18.8 8.3 18.4418 8.3 18H6.7ZM5 2.8H15V1.2H5V2.8ZM17.2 5V15H18.8V5H17.2ZM15 17.2H5V18.8H15V17.2ZM2.8 15V5H1.2V15H2.8ZM5 17.2C3.78497 17.2 2.8 16.215 2.8 15H1.2C1.2 17.0987 2.90132 18.8 5 18.8V17.2ZM17.2 15C17.2 16.215 16.215 17.2 15 17.2V18.8C17.0987 18.8 18.8 17.0987 18.8 15H17.2ZM15 2.8C16.215 2.8 17.2 3.78497 17.2 5H18.8C18.8 2.90132 17.0987 1.2 15 1.2V2.8ZM5 1.2C2.90132 1.2 1.2 2.90132 1.2 5H2.8C2.8 3.78497 3.78497 2.8 5 2.8V1.2ZM6.7 8V18H8.3V8H6.7ZM2.5 8.3H17.5V6.7H2.5V8.3Z"
      fill="currentColor"
    />
  </svg>
);
