import React from 'react';
import MiniMapNode from './miniMapNode';
import { MiniMap } from 'reactflow';

export default function CanvasMimiMap() {
  return (
    <MiniMap
      position="top-right"
      zoomable
      draggable
      nodeComponent={MiniMapNode}
    />
  );
}
