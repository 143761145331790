import React, { useState } from 'react';
import styles from './CustomIconDropdown.module.css';
import { iconList } from '../../../ui/icon';
import IconButton from '../../../ui/iconButton';
import Divider from '../../../ui/divider/Divider';
import { Button } from '../../../ui';
import { useReactFlow } from 'reactflow';
const iconColors = [
  '#45CE66',
  '#45CEC4',
  '#4574CE',
  '#7E45CE',
  '#CE45C5',
  '#CE6145',
  '#CE9E45',
];

export default function ListIconMaterial({ id }) {
  const { setNodes } = useReactFlow();
  const [selectedColor, setSelectedColor] = useState(iconColors[0]);

  const saveNewIcon = (icon) => {
    setNodes((nodes) => {
      const updateNodes = nodes.map((node) => {
        if (node.id === id) {
          return {
            ...node,
            data: { ...node?.data, icon, backgroundColor: selectedColor },
          };
        }
        return node;
      });
      return updateNodes;
    });
  };
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const iconListFiltered = iconList.filter((icon) =>
    icon.toLowerCase().includes(inputValue.toLowerCase()),
  );
  return (
    <div className={styles.listWrapper}>
      <Divider>Material Icons:</Divider>
      <input
        placeholder="Search icon..."
        type="text"
        value={inputValue}
        onChange={handleInputChange}
      />
      <div className={styles.buttonColorBox}>
        {iconColors.map((color) => (
          <button
            key={color}
            className={`${styles.buttonColor} ${
              selectedColor === color && 'active'
            }`}
            onClick={() => setSelectedColor(color)}
          >
            <div style={{ backgroundColor: color }}></div>
          </button>
        ))}
      </div>

      {iconListFiltered.length > 0 && (
        <div className={styles.list}>
          {iconListFiltered.map((icon) => (
            <IconButton
              key={icon}
              color={selectedColor}
              name={icon}
              onClick={() => saveNewIcon(`${icon}&material`)}
            />
          ))}
        </div>
      )}
      <div
        className={`${styles.noResult} ${
          iconListFiltered.length > 0 && 'hidden'
        }`}
      >
        <div>No result</div>
        <Button
          label={'Reset'}
          onClick={(event) => {
            event.preventDefault();
            setInputValue('');
          }}
        />
      </div>
    </div>
  );
}
