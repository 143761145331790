export const blogPlanner = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.5 3.5H8H5C4.44771 3.5 4 3.94771 4 4.5V21C4 21.5523 4.44771 22 5 22H19C19.5523 22 20 21.5523 20 21V4.5C20 3.94771 19.5523 3.5 19 3.5H16.525H15.5"
      stroke="white"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.5 2H8.5V5H15.5V2Z"
      stroke="white"
      strokeWidth="1.6"
      strokeLinejoin="round"
    />
    <path
      d="M10.3598 10H16.7998M10.3598 13.24H16.7998M10.3598 16.48H16.7998M7.7998 10V10.0064M7.7998 13.24V13.2464M7.7998 16.48V16.4864"
      stroke="white"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
