import React, { useState } from 'react';
import { Handle, Position } from 'reactflow';
import styles from './Title.module.css';
import { IconButton } from '../../../ui';
import { getNextSinglePosition } from './utils';

export function hocCanvasElementsConditionWithNode(WrappedComponent) {
  return function ActionsNodeComponent(props) {
    const title = props?.data?.title;
    //todo useStore for titleVisible
    //error [React Flow]: It looks like you've created a new nodeTypes or edgeTypes object.
    const titleVisible = props?.titleVisible ?? 'always';
    const [yesPosition, setYesPosition] = useState(Position.Right);
    const revertHandle = () => {
      console.log('revertHandle');

      setYesPosition((position) => {
        const nextPosition = getNextSinglePosition(position);
        return nextPosition;
      });
    };
    // getNextSinglePosition(position?.first);

    const targetPosition = getNextSinglePosition(yesPosition);
    const noPosition = getNextSinglePosition(targetPosition);
    return (
      <>
        <Handle
          type="target"
          className={styles.handle}
          position={targetPosition}
          id="top"
        />
        <Handle
          type="source"
          className={styles.handle}
          position={yesPosition}
          id="yes"
          style={{
            background: 'var(--System-Success-600)',
            border: '1px solid var(--System-Success-600)',
          }}
        />
        <Handle
          type="source"
          className={styles.handle}
          position={noPosition}
          id="no"
          style={{
            background: 'var(--System-Warning-600)',
            border: '1px solid var(--System-Warning-600)',
          }}
        />
        <div style={{ padding: '2px' }}>
          <div className={styles.root}>
            <div className={styles.settings}>
              <IconButton name={'cached'} onClick={revertHandle} />
            </div>
            <WrappedComponent
              {...{ ...props, ...props?.data, titleOff: true }}
            />
            {titleVisible !== 'never' && (
              <p
                className={`${styles.title} ${
                  titleVisible === 'always' && 'always'
                }`}
              >
                {title}
              </p>
            )}
          </div>
        </div>
      </>
    );
  };
}
