import React from 'react';
import styles from './CustomIconDropdown.module.css';
import Dropdown from '../../../ui/dropdown/Dropdown';
import CustomIconDropdownContent from './CustomIconDropdownContent';

export default function CustomIconDropdown({ id, defaultData }) {
  return (
    <Dropdown
      className={styles.dropdown}
      classNameContent={styles.dropdownContent}
    >
      <CustomIconDropdownContent {...{ id, defaultData }} />
    </Dropdown>
  );
}
