import React from 'react';
import { Button, ContextMenu, Dropdown } from '../../../ui';
import { MarkerType, useEdges, useReactFlow } from 'reactflow';
import { edgeTypesButton } from '../../customEdge';
import { IconCustom } from '../../../ui/icon';

export default function EdgeContextMenu({ stateUndo, contextMenu, closeMenu }) {
  const { setEdges } = useReactFlow();
  const edges = useEdges();

  const typePath =
    edges.find((item) => item.id === contextMenu?.id)?.data?.typePath ??
    'bezier';
  const animated = edges.find((item) => item.id === contextMenu?.id)?.animated;
  const markerStart =
    edges.find((item) => item.id === contextMenu?.id)?.markerStart?.type ??
    false;
  const markerEnd =
    edges.find((item) => item.id === contextMenu?.id)?.markerEnd?.type ?? false;

  const setEdgeType = (value) => {
    setEdges((edges) => {
      stateUndo.current = [
        ...stateUndo.current,
        { type: 'edges', data: edges },
      ];
      return edges.map((item) =>
        item.id === contextMenu?.id
          ? { ...item, data: { ...item?.data, typePath: value } }
          : item,
      );
    });
  };
  const setEdgeTypeLine = () => {
    setEdges((edges) => {
      stateUndo.current = [
        ...stateUndo.current,
        { type: 'edges', data: edges },
      ];
      return edges.map((item) =>
        item.id === contextMenu?.id
          ? { ...item, animated: !item?.animated }
          : item,
      );
    });
  };
  const setEdgeReverse = () => {
    setEdges((edges) => {
      stateUndo.current = [
        ...stateUndo.current,
        { type: 'edges', data: edges },
      ];
      return edges.map((item) =>
        item.id === contextMenu?.id
          ? {
              ...item,
              target: item?.source,
              targetHandle: item?.sourceHandle,
              source: item?.target,
              sourceHandle: item?.targetHandle,
            }
          : item,
      );
    });
  };
  const setEdgeMarkerStar = () => {
    setEdges((edges) => {
      stateUndo.current = [
        ...stateUndo.current,
        { type: 'edges', data: edges },
      ];
      return edges.map((item) =>
        item.id === contextMenu?.id
          ? {
              ...item,
              markerStart: item.markerStart?.type
                ? {}
                : {
                    type: MarkerType.ArrowClosed,
                    width: 20,
                    height: 20,
                    color: 'var(--Primary-600, #5E21C0)',
                  },
            }
          : item,
      );
    });
  };
  const setEdgeMarkerEnd = () => {
    setEdges((edges) => {
      stateUndo.current = [
        ...stateUndo.current,
        { type: 'edges', data: edges },
      ];
      return edges.map((item) =>
        item.id === contextMenu?.id
          ? {
              ...item,
              markerEnd: item.markerEnd?.type
                ? {}
                : {
                    type: MarkerType.ArrowClosed,
                    width: 20,
                    height: 20,
                    color: 'var(--Primary-600, #5E21C0)',
                  },
            }
          : item,
      );
    });
  };

  const delEdge = () => {
    setEdges((edges) => {
      stateUndo.current = [
        ...stateUndo.current,
        { type: 'edges', data: edges },
      ];
      return edges.filter((item) => item.id !== contextMenu?.id);
    });
    if (typeof closeMenu === 'function') {
      closeMenu();
    }
  };

  const isOpen = contextMenu?.isOpen && contextMenu?.type === 'edge';

  const labelclear = typePath === 'default' ? 'bezier' : typePath;
  const label = labelclear.charAt(0).toUpperCase() + labelclear.slice(1);
  const labelDropdown = <IconCustom name={`line${label}`} />;
  return (
    <ContextMenu
      position={contextMenu?.position}
      isOpen={isOpen}
      onClose={closeMenu}
    >
      <Dropdown label={labelDropdown}>
        {edgeTypesButton.map(({ label, value }, i) => (
          <Button
            active={value === typePath}
            key={value}
            style={{ width: '100%' }}
            onClick={() => setEdgeType(value)}
          >
            {label}
            <IconCustom name={`line${label}`} />
          </Button>
        ))}
      </Dropdown>
      <Button
        onClick={setEdgeTypeLine}
        icon={animated ? 'horizontal_rule' : 'unknown_med'}
      />
      <Button onClick={setEdgeReverse} icon={'cached'} />
      <Button
        onClick={setEdgeMarkerStar}
        active={markerStart}
        icon={markerStart ? 'line_start_arrow' : 'line_start'}
      />
      <Button
        onClick={setEdgeMarkerEnd}
        active={markerEnd}
        icon={markerEnd ? 'line_end_arrow' : 'line_end'}
      />

      <Button icon={'delete'} onClick={delEdge} />
    </ContextMenu>
  );
}
