import React from 'react';
import { Button, ContextMenu } from '../../../ui';
import { useNodes, useReactFlow } from 'reactflow';
import styles from './NodeContextMenu.module.css';

export default function NodeContextMenu({
  stateUndo,
  contextMenu,
  closeMenu,
  openSideBar,
  isOpenSideBar,
}) {
  const { setNodes } = useReactFlow();
  const nodes = useNodes();

  // const openModal = () => {
  //   if (!isOpenSideBar) {
  //     typeof closeMenu === 'function' && openSideBar();
  //     typeof closeMenu === 'function' && closeMenu();
  //   }
  // };

  const delNode = () => {
    stateUndo.current = [...stateUndo.current, { type: 'nodes', data: nodes }];
    setNodes((nodes) => {
      return nodes.filter((item) => item.id !== contextMenu?.id);
    });
    typeof closeMenu === 'function' && closeMenu();
  };

  const isOpen = contextMenu?.isOpen && contextMenu?.type === 'node';

  return (
    <ContextMenu
      position={contextMenu?.position}
      isOpen={isOpen}
      onClose={closeMenu}
      buttonClose={false}
    >
      <div className={styles.root}>
        {/* <Button
          disabled={isOpenSideBar}
          icon={'more_vert'}
          onClick={openModal}
        /> */}
        <Button icon={'delete'} onClick={delNode} />
      </div>
    </ContextMenu>
  );
}
