export const tag = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M15.5475 7.93608L15.5419 7.93643M18.0318 3.54568L12.9357 3.50006C12.4839 3.49602 12.0529 3.68988 11.7557 4.03076L4.08189 12.8353C3.5198 13.4802 3.58623 14.4593 4.23025 15.0221L10.0608 20.1178C10.7048 20.6806 11.6826 20.6141 12.2447 19.9692L19.9185 11.1647C20.2156 10.8238 20.3491 10.3699 20.284 9.92221L19.5496 4.87217C19.4396 4.11569 18.7952 3.55251 18.0318 3.54568Z"
      stroke="white"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
