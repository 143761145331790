import { IconCustom } from '../../../../ui/icon';
import styles from './ControlButtonWrapper.module.css';
import { Button } from '../../../../ui';

export default function ControlButtonWrapper({ onClick, active, label, icon }) {
  const title = label;
  return (
    <Button
      {...{
        active,
        title,
        onClick,
        className: styles.root,
      }}
    >
      <IconCustom name={icon} />
    </Button>
  );
}
