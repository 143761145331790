import DevTools from './DevTools';

export default function DevToolsWrapper() {
  const isDevTools =
    process.env.REACT_APP_isCanvasDevTools === 'true' ? true : false;
  if (isDevTools) {
    return null;
  }
  return <DevTools />;
}
