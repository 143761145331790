export const condition = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
  >
    <path
      d="M3.98836 16.8944L6.56461 14.2033M3.98836 16.8944L1.41211 14.2033M3.98836 16.8944C3.98836 12.0263 3.12961 8.37248 6.56461 8.37248C7.37711 8.37248 7.99742 8.16828 8.47101 7.82763M16.0109 16.8944L13.4346 14.2033M16.0109 16.8944L18.5871 14.2033M16.0109 16.8944C16.0109 12.0263 16.8696 8.37251 13.4346 8.37251C9.99961 8.37251 9.91774 5.23782 10.0145 0.947292"
      stroke="white"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
